import React, { useState, useEffect } from "react";
import { baseURL } from "../../../utils/api/apiStrapi";
import apiStrapi from "../../../utils/api/apiStrapi";
import { message, customConfirm } from "../../../utils/notification";
import CreateEntry from "./createEntry";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialTable from "material-table";
import materialTable from "../../../utils/materialTableUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faClipboard } from "@fortawesome/free-solid-svg-icons";
import "../../../css/global.css";
import "../../../css/materialTable.css";

const initialState = {
  suplierId: "",
  orderOfServiceId: "",
  date: "",
  description: "",
  image: null,
};

const initialItems = {
  materialToolsId: "",
  code: "",
  name: "",
  reference: "",
  numberOfReference: "",
  amount: "",
  materialToolsName: "",
  unitName: "",
};

const columns = [
  // {
  //   title: "Codigo",
  //   field: "code",
  //   headerStyle: { fontWeight: "bold" },
  // },
  {
    title: "Numero de parte",
    field: "reference",
    headerStyle: { fontWeight: "bold" },
  },
  {
    title: "Numero de serial",
    field: "numberOfReference",
    headerStyle: { fontWeight: "bold" },
  },
  {
    title: "Cantidad",
    field: "amount",
    type: "numeric",
    headerStyle: { fontWeight: "bold" },
  },
];

const initialInputValue = { id: 1, name: "Seleccione" };

export default function EntryMaterialsTools() {
  const [form, setForm] = useState({ ...initialState });
  const [suplier, setSuplier] = useState([]);
  const [entries, setEntries] = useState([]);
  const [orderOfServices, setOrderOfServices] = useState([]);
  const [list, setList] = useState([]);
  const [items, setItems] = useState({ ...initialItems });
  const [data, setData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [validated, setValidated] = useState(false);
  const [validatedItems, setValidatedItems] = useState(false);
  const [file, setFile] = useState({ image: null });
  const [isUpdateItem, setIsUpdateItem] = useState(false);
  const [inputValue, setInputValue] = useState({ ...initialInputValue });
  const [itemsOfEntry, setItemsOfEntry] = useState([]);
  const [itemsOfEntryId, setItemsOfEntryId] = useState({ id: null });
  const [urlFacture, setUrlFacture] = useState({
    urlThumbnail: null,
    urlLarge: null,
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleChangeFile = (e) => {
    setFile({ image: [...e.target.files] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formControl = e.currentTarget;
    const isValid = formControl.checkValidity() !== false;
    setValidated(true);
    if (isValid) {
      try {
        if (isUpdate && form.image.length) {
          const position = form.image.length - 1;
          await apiStrapi.delete(`upload/files/${form.image[position].id}`);
        }
        const dataNew = new FormData();
        if (file.image?.length) {
          file.image.forEach((imageFile) => {
            dataNew.append(`files.image`, imageFile);
          });
        }
        delete form.image;
        dataNew.append("data", JSON.stringify(form));
        const result = isUpdate
          ? await apiStrapi.put(`entries/${form.id}`, dataNew)
          : await apiStrapi.post("entries", dataNew);
        if (result.data) {
          const entrieId = result.data.id;
          const model = {
            entrieId,
            items: JSON.stringify(data),
          };
          if (isUpdate && itemsOfEntryId.id != null) {
            const result = await apiStrapi.put(
              `items-of-entries/${itemsOfEntryId.id}`,
              model
            );
            if (result.data) {
              message("Actualizado con exito", 1);
              handleReset();
            }
          } else {
            const result = await apiStrapi.post("items-of-entries", model);
            if (result.data) {
              message("Guardado con exito", 1);
              handleReset();
            }
          }
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const loadData = async () => {
    try {
      const result = await apiStrapi.get("entries?_limit=-1");
      const data = result.data;
      if (data) {
        data.forEach((item) => {
          const orderOfServiceName = item.orderOfServiceId
            ? item.orderOfServiceId.nameOs
            : null;
          const orderOfServiceId = item.orderOfServiceId
            ? item.orderOfServiceId.id
            : null;
          const suplierName = item.suplierId ? item.suplierId.name : null;
          const suplierId = item.suplierId ? item.suplierId.id : null;
          item.orderOfServiceName = orderOfServiceName;
          item.orderOfServiceId = orderOfServiceId;
          item.suplierName = suplierName;
          item.suplierId = suplierId;
          return item;
        });
        setEntries(data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadItemsOfEntry = async () => {
    try {
      const result = await apiStrapi.get("items-of-entries?_limit=-1");
      result.data ? setItemsOfEntry(result.data) : setItemsOfEntry([]);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadSuplier = async () => {
    try {
      const result = await apiStrapi.get("supliers?_limit=-1");
      result.data ? setSuplier(result.data) : setSuplier([]);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadOrderOfServices = async () => {
    try {
      const result = await apiStrapi.get("order-of-services?_limit=-1");
      result.data ? setOrderOfServices(result.data) : setOrderOfServices([]);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadMaterialTools = async () => {
    try {
      const result = await apiStrapi.get("material-tools?_limit=-1");
      const data = result.data;
      data.forEach((item) => {
        const categoryName = item.category != null ? item.category.name : null;
        const category = item.category != null ? item.category.id : null;
        item.categoryName = categoryName;
        item.category = category;
      });
      if (data) setList(data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const handleDeleteItems = async (rowData) => {
    const confir = await customConfirm(`Desea quitar el item ${rowData.name}`);
    if (confir) {
      const result = data.filter((item) => item.code != rowData.code && item);
      setData(result);
    }
  };

  const addItems = (e) => {
    if (!isUpdateItem) {
      e.preventDefault();
      const formControl = e.currentTarget;
      const isValid = formControl.checkValidity() !== false;
      setValidatedItems(true);
      if (items.code == "") {
        message("Debe seleccionar un item", 2);
      } else if (items.amount == "") {
        message("Debe escribir una cantidad", 2);
      } else {
        const valit = data.filter((item) => item.code == items.code);
        if (!valit.length) {
          setData([...data, items]);
          setItems({ ...initialItems });
          setInputValue({ ...initialInputValue });
          setValidatedItems(false);
        } else {
          message("Este item ya esta agregado", 2);
        }
      }
    } else {
      const newData = data.filter((item) => item.code != items.code && item);
      const dataFinal = newData.concat(items);
      setData(dataFinal);
      setItems({ ...initialItems });
      setInputValue({ ...initialInputValue });
      setValidatedItems(false);
      setIsUpdateItem(false);
    }
  };

  const queryProdutById = (id) => {
    if (id) {
      list &&
        list.forEach(
          (item) =>
            item.id == id &&
            setItems({
              ...items,
              code: item.code,
              reference: item.reference,
              name: item.name,
              unitName: item.unitId.name,
              materialToolsId: item.id,
            })
        );
    }
  };

  const loadDataUpdate = (rowData) => {
    if (rowData) {
      setIsUpdate(true);
      if (rowData.image.length) {
        const factures = rowData.image?.map((item, index) => {
          return {
            urlThumbnail: `${baseURL}${item.formats.thumbnail.url.slice(1)}`,
            urlLarge: `${baseURL}${item.url.slice(1)}`,
            urlMedium: `${baseURL}${item.formats.medium.url.slice(1)}`,
          };
        });
        setUrlFacture(factures);
      } else setUrlFacture([]);
      if (itemsOfEntry.length) {
        const result = itemsOfEntry.filter(
          (item) => item.entrieId.id == rowData.id && item
        );
        if (result[0] != undefined) {
          setData(result[0].items);
          setItemsOfEntryId({ id: result[0].id });
          setForm(rowData);
        } else {
          setForm(rowData);
          setItemsOfEntryId({ id: null });
          setData([]);
        }
      }
    }
  };

  const loadAutocomplete = async (code) => {
    if (code) {
      try {
        const result = await apiStrapi.get(
          `material-tools?_where[code]=${code}`
        );
        const data = result.data[0];
        setInputValue(data);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const handleDelete = async (rowData) => {
    const confir = await customConfirm(
      `Desea eliminar la entrada con id #${rowData.id}`
    );
    if (confir) {
      const remove = await apiStrapi.get(
        `items-of-entries?_where[entrieId]=${rowData.id}`
      );
      if (remove.data.length) {
        const removeItems = await apiStrapi.delete(
          `items-of-entries/${remove.data[0].id}`
        );
        if (removeItems.status == 200) {
          await apiStrapi.delete(`entries/${rowData.id}`);
          handleReset();
          message("Entrada eliminada con exito", 1);
        }
      }
    }
  };

  const handleReset = () => {
    setForm({ ...initialState });
    setItems({ ...items, amount: "" });
    setValidated(false);
    setData([]);
    setIsUpdate(false);
    loadData();
    loadItemsOfEntry();
  };

  useEffect(() => {
    loadSuplier();
    loadOrderOfServices();
    loadMaterialTools();
    loadData();
    loadItemsOfEntry();
  }, []);

  useEffect(() => {
    loadAutocomplete(items.code);
  }, [items.code]);

  useEffect(() => {
    queryProdutById(items.materialToolsId);
  }, [items.materialToolsId]);

  return (
    <>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h2 className="titleHeader">
          {isUpdate
            ? "Actualización de ingreso de materiales y herramientas"
            : "Ingreso de materiales y herramientas"}
        </h2>
      </Card.Header>
      <Card.Body>
        <CreateEntry
          handleSubmit={handleSubmit}
          form={form}
          handleChange={handleChange}
          suplier={suplier}
          items={items}
          data={data.map((item) => {
            const { unitId, numberOfReference } = list.find(
              (x) => x.code === item.code
            );

            return {
              ...item,
              unitName: unitId?.name,
              numberOfReference,
            };
          })}
          setData={setData}
          setItems={setItems}
          orderOfServices={orderOfServices}
          list={list}
          addItems={addItems}
          columns={columns}
          validated={validated}
          validatedItems={validatedItems}
          handleChangeFile={handleChangeFile}
          isUpdateItem={isUpdateItem}
          setIsUpdateItem={setIsUpdateItem}
          inputValue={inputValue}
          entries={entries}
          itemsOfEntry={itemsOfEntry}
          loadDataUpdate={loadDataUpdate}
          isUpdate={isUpdate}
          handleReset={handleReset}
          urlFacture={urlFacture}
          handleDeleteItems={handleDeleteItems}
          handleDelete={handleDelete}
        />
      </Card.Body>
    </>
  );
}
