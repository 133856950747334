import React, { useState, useEffect } from "react";
import { Card, Form, Col, Row } from "react-bootstrap";
import MaterialTable from "material-table";
import materialTable from "../../utils/materialTableUtils";
import apiStrapi from "../../utils/api/apiStrapi";
import "../../css/global.css";
import { formatDataReportByOs } from "utils/formatDataReportByOs";

const initialState = { orderOfServiceId: "" };

const options = {
  // // headerStyle: { backgroundColor: '#253053', color: 'white' },

  filtering: false,
  grouping: false,
  exportButton: true,
  actionsColumnIndex: -1,
  pageSize: 1000,
  paging: true,
  pageSizeOptions: [5, 10, 20, 50, 100, 500, 1000],
  emptyRowsWhenPaging: false,
  search: true,
  sorting: true,
  toolbar: true,
  padding: "dense",
};

export default function ReportStockByOs() {
  const [form, setForm] = useState({ ...initialState });
  const [data, setData] = useState([]);
  const [orderOfServices, setOrderOfServices] = useState([]);
  const [materials, setMaterials] = useState([]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const loadAllData = async () => {
    try {
      const orders = await apiStrapi.get("order-of-services?_limit=-1");
      if (orders?.data) setOrderOfServices(orders.data);
      const materials = await apiStrapi.get("material-tools?_limit=-1");
      if (materials?.data) setMaterials(materials.data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const resultData = async (orderOfServiceId) => {
    if (orderOfServiceId) {
      const itemsEntries = await apiStrapi.get("items-of-entries?_limit=-1");
      const dataEntries = await apiStrapi.get(
        `entries?_limit=-1&orderOfServiceId.id=${orderOfServiceId}`
      );
      const itemsExits = await apiStrapi.get("items-of-exits?_limit=-1");
      const dataExits = await apiStrapi.get(
        `exits?_limit=-1&orderOfServiceId.id=${orderOfServiceId}`
      );

      const entries = formatDataReportByOs(
        itemsEntries.data,
        dataEntries.data,
        materials
      );

      const exits = formatDataReportByOs(
        itemsExits.data,
        dataExits.data,
        materials
      );

      const returns = await apiStrapi.get(
        `returns?orderOfServiceId.id=${orderOfServiceId}`
      );

      const result = materials
        .map((item) => {
          let amountEntry = 0;
          let amountReturn = 0;
          let amountExit = 0;

          const validEntries = entries.some(
            (e) => e.materialToolsId.id === item.id
          );
          const validReturns = returns.data.some(
            (e) => e.materialToolsId.id === item.id
          );
          const validExits = exits.some(
            (e) => e.materialToolsId.id === item.id
          );

          if (!validEntries && !validReturns && !validExits) return false;

          entries?.forEach((e) => {
            if (item.code == e.materialToolsId.code)
              amountEntry = amountEntry + parseInt(e.amount);
          });
          returns.data?.forEach((e) => {
            if (item.code == e.materialToolsId.code)
              amountReturn = amountReturn + parseInt(e.amount);
          });
          exits?.forEach((e) => {
            if (item.code == e.materialToolsId.code)
              amountExit = amountExit + parseInt(e.amount);
          });

          item.amount = amountEntry + amountReturn - amountExit;
          return item;
        })
        .filter((e) => e);

      setData(result);
    }
  };

  useEffect(() => {
    loadAllData();
  }, []);

  useEffect(() => {
    resultData(form.orderOfServiceId);
  }, [form.orderOfServiceId]);

  return (
    <>
      <Card.Header>
        <h2 className="titleHeader">Inventario de Materiales/Herramientas</h2>
      </Card.Header>
      <Card.Body>
        <Form.Group className="sm-4 col-sm-4">
          <p className="labelStyle">Orden de Trabajo *</p>
          <Form.Control
            as="select"
            name="orderOfServiceId"
            value={form.orderOfServiceId}
            onChange={handleChange}
            placeholder="Producto"
            required
          >
            <option value="">Seleccione</option>
            {orderOfServices &&
              orderOfServices.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.nameOs}
                </option>
              ))}
          </Form.Control>
        </Form.Group>
        <MaterialTable
          columns={[
            {
              title: "Codigo",
              field: "code",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Orden de Trabajo",
              field: "orderOfServiceName",
              headerStyle: { fontWeight: "bold" },
              render: (rowData) =>
                orderOfServices.find((e) => e.id == form.orderOfServiceId)
                  ?.nameOs,
            },
            {
              title: "Nombre del item",
              field: "name",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Numero de parte",
              field: "reference",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Stock",
              field: "amount",
              headerStyle: { fontWeight: "bold" },
            },
          ]}
          options={options}
          localization={materialTable.localization}
          data={data.length ? data : []}
          title="Reporte de stock"
        />
      </Card.Body>
    </>
  );
}
