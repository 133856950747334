import React from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import "../../../css/global.css";
import MaterialTable from "material-table";
import materialTable from "../../../utils/materialTableUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

export default function CreateActivities(props) {
  return (
    <>
      <div className="d-flex justify-content-end">
        <Button variant="primary" onClick={props.showModal}>
          Actualizar guia de Activiades
        </Button>
      </div>
      <Form>
        <Row>
          <Col sm="4">
            <Form.Group>
              <p className="labelStyle">Orden de Trabajo *</p>
              <Form.Control
                as="select"
                name="orderOfServiceId"
                value={props.form.orderOfServiceId}
                onChange={props.handleChange}
                required
              >
                <option value="">Seleccione</option>
                {props.orderOfServices &&
                  props.orderOfServices.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.numberOs} - {item.nameOs}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </Form>

      <Form
        onSubmit={props.addItems}
        noValidate={true}
        className={props.validatedItems ? "was-validated" : ""}
      >
        <Row>
          <Col sm="2">
            <Form.Group>
              <p className="labelStyle">Item *</p>
              <Form.Control
                type="text"
                name="item"
                value={props.items.item}
                onChange={props.handleChangeItems}
                min="1"
                required
              />
            </Form.Group>
          </Col>
          <Col sm="4">
            <Form.Group>
              <p className="labelStyle">Descripción *</p>
              <Form.Control
                type="text"
                name="description"
                value={props.items.description}
                onChange={props.handleChangeItems}
              />
            </Form.Group>
          </Col>

          <Col sm="2">
            <Form.Group>
              <p className="labelStyle">Und *</p>
              <Form.Control
                as="select"
                name="unitId"
                value={props.items.unitId}
                onChange={props.handleChangeItems}
                required
              >
                <option value="">Seleccione</option>
                {props.units &&
                  props.units.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm="2">
            <Form.Group>
              <p className="labelStyle">Cantidad *</p>
              <Form.Control
                type="number"
                name="amount"
                value={props.items.amount}
                onChange={props.handleChangeItems}
                onKeyDown={props.noPuntoComa}
                min="1"
                required
              />
            </Form.Group>
          </Col>
          <Col sm="2">
            <Form.Group>
              <p className="labelStyle">Valor unitario</p>
              <Form.Control
                type="number"
                name="valueUnit"
                value={props.items.valueUnit}
                onChange={props.handleChangeItems}
                step="0.01"
                min="1"
              />
            </Form.Group>
          </Col>
          <Col sm="2">
            <Form.Group>
              <p className="labelStyle">Valor Total</p>
              <Form.Control
                type="text"
                name="valueTotal"
                value={
                  props.items.amount && props.items.valueUnit
                    ? props.items.amount * props.items.valueUnit
                    : ""
                }
                onChange={props.handleChangeItems}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="submit" size="sm" variant="outline-success">
              {props.isUpdateItem ? "Actualizar item" : "Agregar actividad"}
            </Button>
          </Col>
        </Row>
      </Form>

      <MaterialTable
        columns={[
          {
            title: "Item",
            field: "item",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Descripción",
            field: "description",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Unidad",
            field: "unitName",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Cantidad",
            field: "amount",
            type: "numeric",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Valor unitario",
            field: "valueUnitNew",
            type: "text",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Valor total",
            field: "valueTotalNew",
            type: "text",
            headerStyle: { fontWeight: "bold" },
          },
        ]}
        data={props.data}
        options={props.options}
        localization={materialTable.localization}
        title="Registro de actividades"
        editable={{
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...props.data];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                props.setData([...dataDelete]);

                resolve();
              }, 1000);
            }),
        }}
        actions={[
          {
            icon: () => <FontAwesomeIcon icon={faPen} />,
            tooltip: "Editar",
            onClick: (event, rowData) => {
              props.handleUpdate(rowData);
            },
          },
        ]}
      />
      {props.data.length > 0 && (
        <div>
          <Button variant="primary" onClick={props.handleSubmit}>
            {props.isUpdate ? "Actualizar" : "Guardar"}
          </Button>
          {props.isUpdate && (
            <Button
              variant="danger"
              onClick={() => {
                props.deleteRow(props.form.orderOfServiceId);
              }}
            >
              Eliminar
            </Button>
          )}
        </div>
      )}
    </>
  );
}
