import React, { useState, useEffect } from "react";
import { Card, Tabs, Tab, Spinner } from "react-bootstrap";
import EntryMaterialsTools from "./entryMaterialTools/entryMaterialsTools";
import ExitMaterialsTools from "./exitMaterialTools/exitMaterialsTools";
import ReturnRemainderTools from "./returnRemainder/returnRemainderTools";
import StockReport from "./stockReport";
import "../../css/global.css";
import { useSelector } from "react-redux";
import apiStrapi from "../../utils/api/apiStrapi";

export default function Home() {
  const user = useSelector(({ user }) => user.value);
  const [key, setKey] = useState("entry");
  const [loading, setLoading] = useState(true);

  /////////Permisos del usuario /////////////////////
  const [entry, setEntry] = useState(false);
  const [exit, setExit] = useState(false);
  const [returnRemainder, setReturnRemainder] = useState(false);
  const [stock, setStock] = useState(false);
  const namePermissionWareHouse = 1;
  const namePermissionEntry = 2;
  const namePermissionExit = 3;
  const namePermissionReturnRemainder = 4;
  const namePermissionStock = 5;
  ////////////////////////////////////////////////////

  const validatedPermissions = async () => {
    try {
      const result = await apiStrapi.get("permissions?_limit=-1");
      if (result.data.length) {
        //permission for view model
        const accessToModule = result.data.some(
          (item) =>
            item.namePermissionId.id == namePermissionWareHouse &&
            item.roleId.id == user.roleId.id
        );

        if (!accessToModule) {
          window.location.href = "/admin/wellcome";
        } else {
          const result1 = result.data.some(
            (item) =>
              item.namePermissionId.id == namePermissionEntry &&
              item.roleId.id == user.roleId.id
          );
          if (result1) setEntry(true);

          const result2 = result.data.some(
            (item) =>
              item.namePermissionId.id == namePermissionExit &&
              item.roleId.id == user.roleId.id
          );
          if (result2) setExit(true);

          const result3 = result.data.some(
            (item) =>
              item.namePermissionId.id == namePermissionReturnRemainder &&
              item.roleId.id == user.roleId.id
          );

          if (result3) setReturnRemainder(true);
          const result4 = result.data.some(
            (item) =>
              item.namePermissionId.id == namePermissionStock &&
              item.roleId.id == user.roleId.id
          );

          if (result4) setStock(true);

          if (result1) setKey("entry");
          else if (result2) setKey("exit");
          else if (result3) setKey("Return/remainder");
          else if (result4) setKey("StockReport");

          setLoading(false);
        }
      }
    } catch (error) {
      console.log(`error.message`, error.message);
    }
  };

  useEffect(() => {
    validatedPermissions();
  }, []);

  return (
    <>
      <div className="content fluid">
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="grow" role="status">
              <span className="visually-hidden"></span>
            </Spinner>
          </div>
        ) : (
          <Card>
            <Tabs
              style={{
                fontSize: 15,
                margin: 2,
                backgroundColor: "",
                color: "#252422",
                fontWeight: "bold",
              }}
              className="m-3"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              unmountOnExit={true}
              mountOnEnter={true}
            >
              {entry && (
                <Tab eventKey="entry" title="Ingreso" tabClassName="colorTab">
                  <EntryMaterialsTools />
                </Tab>
              )}
              {exit && (
                <Tab eventKey="exit" title="Salida" tabClassName="colorTab">
                  <ExitMaterialsTools />
                </Tab>
              )}
              {returnRemainder && (
                <Tab
                  eventKey="Return/remainder"
                  title="Devolucion/Remanente"
                  tabClassName="colorTab"
                >
                  <ReturnRemainderTools />
                </Tab>
              )}
              {stock && (
                <Tab
                  eventKey="StockReport"
                  title="Inventario"
                  tabClassName="colorTab"
                >
                  <StockReport />
                </Tab>
              )}
            </Tabs>
          </Card>
        )}
      </div>
    </>
  );
}
