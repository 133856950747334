import React, { useState, useEffect } from "react";
import { Card, Form, Row, Col, Button, Modal, Carousel } from "react-bootstrap";
import MaterialTable from "material-table";
import materialTable from "../../../utils/materialTableUtils";
import { message, customConfirm } from "../../../utils/notification";
import "../../../css/global.css";
import apiStrapi from "../../../utils/api/apiStrapi";
import { baseURL } from "../../../utils/api/apiStrapi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

const initialState = {
  orderOfServiceId: "",
  date1: "",
  date2: "",
  unitId: "",
  amount: "",
  activitiesId: "",
  description: "",
  date: "",
  amountStock: "",
  orderOfserviceName: "",
  image: [],
};

const initialFormItems = {
  employeeId: "",
  timers: "",
  employeeName: "",
};

export default function Execution() {
  const [form, setForm] = useState({ ...initialState });
  const [formItems, setFormItems] = useState({ ...initialFormItems });
  const [orderOfServices, setOrderOfServices] = useState([]);
  const [activities, setActivities] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [validated, setValidated] = useState(false);
  const [validatedItems, setValidatedItems] = useState(false);
  const [data, setData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [workProgress, setWorkProgress] = useState(false);
  const [dataWorkProgress, setDataWorkProgress] = useState([]);
  const [executions, setExecutions] = useState([]);
  const [units, setUnits] = useState([]);
  const [perssonelsData, setPerssonelsData] = useState([]);
  const [perssonelWorkProgress, setPerssonelWorkProgress] = useState([]);
  const [isUpdateItem, setIsUpdateItem] = useState(false);
  const [isCreateProgress, setIsCreateProgress] = useState(false);
  const [show, setShow] = useState(false);
  const [file, setFile] = useState([]);

  const loadOrderOfServices = async () => {
    try {
      const result = await apiStrapi.get("order-of-services?_limit=-1");
      if (result.data) {
        const data = result.data
          .map((item) => item.statusesId.id == 1 && item)
          .filter((item) => item != false);
        if (data.length) setOrderOfServices(data);
      }
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const showModal = () => setShow(!show);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleChangeItems = (e) => {
    setFormItems({ ...formItems, [e.target.name]: e.target.value });
  };

  const handleChangeFile = (e) => {
    const files = [...e.target.files];
    setFile(files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formControl = e.currentTarget;
      const isValid = formControl.checkValidity() !== false;
      setValidated(true);
      if (isValid) {
        const model = {
          orderOfServiceId: form.orderOfServiceId,
          date1: form.date1,
          date2: form.date2,
          activitiesId: form.activitiesId,
          unitId: form.unitId,
          amount: form.amount,
        };
        if (!isUpdate) {
          const createExecution = await apiStrapi.post("executions", model);
          const createWorkProgress = await apiStrapi.post(
            "work-progresses",
            model
          );

          if (createExecution.data) {
            data.length &&
              data.map(async (item) => {
                const personnelsModel = {
                  executionId: createExecution.data.id,
                  employeeId: item.employeeId,
                  timers: item.timers,
                };
                await apiStrapi.post(
                  "construction-personnel-executions",
                  personnelsModel
                );
              });
            message("Ejecución registrada con exito", 1);
            handleReset();
          }
          if (createWorkProgress.data) {
            data.length &&
              data.map(async (item) => {
                const personnelsModel = {
                  workProgressId: createWorkProgress.data.id,
                  employeeId: item.employeeId,
                  timers: item.timers,
                };
                await apiStrapi.post(
                  "construction-per-work-progresses",
                  personnelsModel
                );
              });
          }
        } else {
          const result = await apiStrapi.put(`executions/${form.id}`, model);
          if (result.data) {
            const removeData =
              perssonelsData.length &&
              perssonelsData.map(async (item) => {
                const data = await apiStrapi.delete(
                  `construction-personnel-executions/${item.id}`
                );
                return data;
              });
            const saveData =
              data.length &&
              data.map(async (item) => {
                const obj = {
                  executionId: result.data.id,
                  employeeId: item.employeeId,
                  timers: item.timers,
                };
                const save = await apiStrapi.post(
                  `construction-personnel-executions`,
                  obj
                );
                return save;
              });
            message("Ejecución actualizada con exito", 1);
            handleReset();
          }
        }
      }
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const handleLoadCreateProgress = async (rowData) => {
    setIsCreateProgress(true);
    setWorkProgress(false);
    setIsUpdate(false);
    try {
      const model = {
        orderOfServiceId: rowData.orderOfServiceId,
        unitId: rowData.unitId,
        activitiesId: rowData.activitiesId,
      };
      const result = await apiStrapi.get(
        `construction-per-work-progresses?_where[workProgressId.id]=${rowData.id}`,
        form
      );
      if (result.data.length) {
        const data = result.data.map((item) => {
          const model = {
            id: item.id,
            employeeId: item.employeeId.id,
            timers: item.timers,
            employeeName: item.employeeId.fullName,
          };
          return model;
        });
        setPerssonelWorkProgress(data);
        setData(data);
        setForm(model);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleLoadWorkProgress = async (rowData) => {
    console.log(rowData.image);
    setWorkProgress(true);
    setIsUpdate(false);
    try {
      const model = {
        id: rowData.id,
        orderOfServiceId: rowData.orderOfServiceId,
        date1: rowData.date1,
        date2: rowData.date2,
        activitiesId: rowData.activitiesId,
        unitId: rowData.unitId,
        amount: rowData.amount,
        image: rowData.image,
      };
      const result = await apiStrapi.get(
        `construction-per-work-progresses?_where[workProgressId.id]=${rowData.id}`,
        form
      );
      if (result.data.length) {
        const data = result.data.map((item) => {
          const model = {
            id: item.id,
            employeeId: item.employeeId.id,
            timers: item.timers,
            employeeName: item.employeeId.fullName,
          };
          return model;
        });
        setPerssonelWorkProgress(data);
        setData(data);
        setForm(model);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleUpdate = (rowData) => {
    setIsUpdateItem(true);
    setFormItems(rowData);
  };

  const loadExecution = async (planningId) => {
    try {
      const result =
        planningId == null
          ? await apiStrapi.get("executions?_limit=-1")
          : await apiStrapi.get(`executions/${planningId}`);
      const array = [];
      const data =
        planningId == null ? result.data : array.push(result.data) && array;

      if (data) {
        data.forEach((item) => {
          const orderOfServiceId =
            item.activitiesId != null
              ? item.activitiesId.orderOfServiceId
              : null;
          const activitiesId =
            item.activitiesId != null ? item.activitiesId.id : null;
          const activitiesDescription =
            item.activitiesId != null ? item.activitiesId.description : null;
          item.orderOfServiceId = orderOfServiceId;
          item.activitiesId = activitiesId;
          item.activitiesDescription = activitiesDescription;
        });
        if (planningId) {
          return data;
        } else {
          setExecutions(data);
        }
      }
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadEmployees = async () => {
    try {
      const result = await apiStrapi.get("employees?_limit=-1");
      if (result.data) setEmployees(result.data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadUnits = async () => {
    try {
      const result = await apiStrapi.get("units");
      if (result.data) setUnits(result.data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadActivities = async (orderOfServiceId) => {
    if (!workProgress && !isCreateProgress) {
      if (orderOfServiceId) {
        try {
          const result = await apiStrapi.get("activities?_limit=-1");
          const data = result.data;
          const result2 = await apiStrapi.get("plannings?_limit=-1");
          const dataPlannings = result2.data;

          if (data.length) {
            const dataNew = [];
            data.map((item) => {
              if (dataPlannings.length) {
                dataPlannings.map((i) => {
                  if (
                    item.id == i.activitiesId.id &&
                    i.orderOfServiceId.id == orderOfServiceId
                  ) {
                    dataNew.push(item);
                  }
                });
              }
            });
            setActivities(dataNew);
          } else {
            resetItem();
          }
        } catch (error) {
          console.log("Error: ", error.message);
        }
      }
    }
  };

  const addItems = (e) => {
    e.preventDefault();
    const formControl = e.currentTarget;
    const isValid = formControl.checkValidity() !== false;
    setValidatedItems(true);
    if (formItems.timers && formItems.employeeId) {
      const employeeValid =
        data.length &&
        data
          .map((item) => {
            if (item.employeeId == formItems.employeeId) {
              return item;
            }
          })
          .filter((item) => item != undefined);
      if (employeeValid.length && isUpdateItem) {
        const base =
          data.length &&
          data
            .map((item) => item.employeeId != formItems.employeeId && item)
            .filter((i) => i != false);
        employees.length &&
          employees.map((item) => {
            if (item.id == formItems.employeeId) {
              setData([...base, { ...formItems, employeeName: item.fullName }]);
              setFormItems({ ...initialFormItems });
              setValidatedItems(false);
              setIsUpdateItem(false);
            }
          });
      }
      if (employeeValid.length && !isUpdateItem)
        message("El empleado ya esta agregado", 2);
      if (!employeeValid.length) {
        setValidatedItems(false);
        employees.length &&
          employees.map((item) => {
            if (item.id == formItems.employeeId) {
              setData([...data, { ...formItems, employeeName: item.fullName }]);
              setFormItems({ ...initialFormItems });
            }
          });
      }
    }
  };

  const loadRegister = async (orderOfServiceId, activitiesId) => {
    if (!workProgress) {
      console.log(
        "orderOfServiceId, activitiesId",
        orderOfServiceId,
        activitiesId
      );
      if (orderOfServiceId && activitiesId) {
        const newData = await apiStrapi.get(
          `work-progresses?_where[activitiesId.id]=${activitiesId}&[orderOfServiceId.id]=${orderOfServiceId}`
        );

        if (newData.data.length) {
          const personnelsData = await apiStrapi.get(
            `construction-per-work-progresses?_where[workProgressId.id]=${newData.data[0].id}`
          );
          if (personnelsData.data) {
            const data = personnelsData.data.map((item) => {
              const model = {
                id: item.id,
                employeeId: item.employeeId.id,
                timers: item.timers,
                employeeName: item.employeeId.fullName,
              };
              return model;
            });
            setIsCreateProgress(true);
            setWorkProgress(false);
            setIsUpdate(false);
            setData(data);
          }
        } else if (!newData.data.length) {
          const planningsItems = await apiStrapi.get(
            `plannings?_where[activitiesId.id]=${activitiesId}&[orderOfServiceId.id]=${orderOfServiceId}`
          );
          if (planningsItems.data) {
            const personnelsData = await apiStrapi.get(
              `construction-personnels?_where[planningId.id]=${planningsItems.data[0].id}`
            );
            if (personnelsData.data) {
              const data = personnelsData.data.map((item) => {
                const model = {
                  id: item.id,
                  employeeId: item.employeeId.id,
                  timers: item.timers,
                  employeeName: item.employeeId.fullName,
                };
                return model;
              });
              setIsCreateProgress(true);
              setWorkProgress(false);
              setIsUpdate(false);
              setData(data);
            }
          }
        } else {
          setIsCreateProgress(false);
          setWorkProgress(false);
          setIsUpdate(false);
          setData([]);
        }
      }
    }
  };

  const loadWorkProgress = async (orderOfServiceId) => {
    try {
      if (orderOfServiceId != "") {
        const result = await apiStrapi.get(
          `work-progresses?_where[orderOfServiceId.id]=${orderOfServiceId}`
        );
        if (result.data.length) {
          const data = result.data.map((item) => {
            const model = {
              id: item.id,
              item: item.activitiesId.item,
              orderOfServiceId: item.orderOfServiceId.id,
              orderOfServiceName: item.orderOfServiceId.nameOs,
              date1: item.date1,
              date2: item.date2,
              activitiesId: item.activitiesId.id,
              activitiesItem: item.activitiesId.description,
              unitId: item.unitId.id,
              unitName: item.unitId.name,
              amount: item.amount,
              image: item.image,
            };
            return model;
          });
          setDataWorkProgress(data);
        } else {
          setDataWorkProgress([]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const createWorkProgress = async () => {
    const model = {
      orderOfServiceId: form.orderOfServiceId,
      date1: form.date1,
      date2: form.date2,
      activitiesId: form.activitiesId,
      unitId: form.unitId,
      amount: form.amount,
    };
    try {
      const createWorkProgress = await apiStrapi.post("work-progresses", model);
      if (createWorkProgress.data) {
        const id = createWorkProgress.data.id;
        file.length &&
          file.map(async (item) => {
            const data2 = new FormData();
            data2.append("data", JSON.stringify(model));
            data2.append("files.image", item);
            await apiStrapi.put(`work-progresses/${id}`, data2);
          });
        data.length &&
          data.map(async (item) => {
            const personnelsModel = {
              workProgressId: createWorkProgress.data.id,
              employeeId: item.employeeId,
              timers: item.timers,
            };
            await apiStrapi.post(
              "construction-per-work-progresses",
              personnelsModel
            );
          });
        message("Avance creado con exito", 1);
        handleReset();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleUpdateWorkProgress = async () => {
    const model = {
      orderOfServiceId: form.orderOfServiceId,
      date1: form.date1,
      date2: form.date2,
      activitiesId: form.activitiesId,
      unitId: form.unitId,
      amount: form.amount,
    };
    try {
      if (form.image != null) {
        form.image.map(async (item) => {
          await apiStrapi.delete(`upload/files/${item.id}`);
        });
      }

      const createWorkProgress = await apiStrapi.put(
        `work-progresses/${form.id}`,
        model
      );
      if (createWorkProgress.data) {
        const id = createWorkProgress.data.id;
        file.length &&
          file.map(async (item) => {
            const data2 = new FormData();
            data2.append("data", JSON.stringify(model));
            data2.append("files.image", item);
            await apiStrapi.put(`work-progresses/${id}`, data2);
          });

        perssonelWorkProgress.length &&
          perssonelWorkProgress.forEach(async (item) => {
            const removeData = await apiStrapi.delete(
              `construction-per-work-progresses/${item.id}`
            );
          });
      }
      if (createWorkProgress.data) {
        data.length &&
          data.map(async (item) => {
            const personnelsModel = {
              workProgressId: createWorkProgress.data.id,
              employeeId: item.employeeId,
              timers: item.timers,
            };
            await apiStrapi.post(
              "construction-per-work-progresses",
              personnelsModel
            );
          });
        message("Avance Actualizado con exito", 1);
        handleReset();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleReset = () => {
    setValidated(false);
    setValidatedItems();
    setForm({ ...initialState });
    setFormItems({ ...initialFormItems });
    setData([]);
    loadExecution();
    setIsUpdate(false);
    setWorkProgress(false);
    setDataWorkProgress([]);
    setIsCreateProgress(false);
    setFile([]);
  };

  const resetItem = () => {
    if (!workProgress && !isCreateProgress) {
      setData([]);
      setIsUpdate(false);
      setForm({
        ...form,
        date1: "",
        date2: "",
        unitId: "",
        amount: "",
        activitiesId: "",
        description: "",
        date: "",
        amountStock: "",
      });
    } else {
      setForm({
        ...form,
        date1: "",
        date2: "",
        unitId: "",
        amount: "",
        activitiesId: "",
        description: "",
        date: "",
        amountStock: "",
      });
    }
  };

  const loadDataActivities = async (id) => {
    if (id != "") {
      try {
        const removeData = await apiStrapi.get(`activities/${id}`);
        if (removeData.data)
          setForm({ ...form, unitId: removeData.data.unitId.id });
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const noPuntoComa = (event) => {
    var e = event || window.event;
    var key = e.keyCode || e.which;

    if (key === 110 || key === 190 || key === 188) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    loadOrderOfServices();
    loadExecution();
    loadEmployees();
    loadUnits();
  }, []);

  useEffect(() => {
    loadDataActivities(form.activitiesId);
  }, [form.activitiesId]);

  useEffect(() => {
    loadActivities(form.orderOfServiceId);
  }, [form.orderOfServiceId]);

  useEffect(() => {
    resetItem(form.orderOfServiceId);
  }, [form.orderOfServiceId]);

  useEffect(() => {
    loadWorkProgress(form.orderOfServiceId);
  }, [form.orderOfServiceId]);

  useEffect(() => {
    loadRegister(form.orderOfServiceId, form.activitiesId);
  }, [form.orderOfServiceId, form.activitiesId]);

  return (
    <>
      <Card.Body>
        <Form noValidate={true} className={validated ? "was-validated" : ""}>
          <Row>
            <Col lg="4">
              <Form.Group>
                <p className="labelStyle">Orden de Trabajo *</p>
                <Form.Control
                  as="select"
                  name="orderOfServiceId"
                  value={form.orderOfServiceId}
                  onChange={handleChange}
                  placeholder="services"
                  required
                >
                  <option value="">Seleccione</option>
                  {orderOfServices &&
                    orderOfServices.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.numberOs} - {item.nameOs}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group>
                <p className="labelStyle">Fecha inicial *</p>
                <Form.Control
                  type="date"
                  name="date1"
                  value={form.date1}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group>
                <p className="labelStyle">Fecha final *</p>
                <Form.Control
                  type="date"
                  name="date2"
                  value={form.date2}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <Form.Group>
                <p className="labelStyle">Actividad *</p>
                <Form.Control
                  as="select"
                  name="activitiesId"
                  value={form.activitiesId}
                  onChange={handleChange}
                  placeholder=""
                  required
                >
                  <option value="">
                    {activities.length ? "Seleccione" : "Os sin actividades"}
                  </option>
                  {activities &&
                    activities.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.description}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm="2">
              <Form.Group>
                <p className="labelStyle">Unidad</p>
                <Form.Control
                  as="select"
                  name="unitId"
                  value={form.unitId}
                  onChange={handleChange}
                  disabled
                >
                  <option value=""></option>
                  {units &&
                    units.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group>
                <p className="labelStyle">Cantidad *</p>
                <Form.Control
                  type="number"
                  name="amount"
                  value={form.amount}
                  onChange={handleChange}
                  placeholder="Cantidad"
                  onKeyDown={noPuntoComa}
                  min={1}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="formFileMultiple" className="mb-3">
                <Form.Label>Cargue las imagenes del avance</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleChangeFile}
                  multiple
                />
              </Form.Group>
              {workProgress && (
                <Button type="button" onClick={showModal}>
                  Ver imagenes
                </Button>
              )}
            </Col>
          </Row>
        </Form>
        <hr />
        <Form
          noValidate={true}
          className={validatedItems ? "was-validated" : ""}
        >
          <Row>
            <Col sm="6">
              <Form.Group>
                <p className="labelStyle">Mano de obra *</p>
                <Form.Control
                  as="select"
                  name="employeeId"
                  value={formItems.employeeId}
                  onChange={handleChangeItems}
                  required
                >
                  <option value="">Seleccione</option>
                  {employees &&
                    employees.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.fullName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm="6">
              <Form.Group>
                <p className="labelStyle">Duración [d]*</p>
                <Form.Control
                  type="number"
                  name="timers"
                  value={formItems.timers}
                  onChange={handleChangeItems}
                  placeholder="Tiempo"
                  onKeyDown={noPuntoComa}
                  min="1"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Button size="sm" variant="outline-success" onClick={addItems}>
            {isUpdateItem ? "Actualizar empleado" : "Agregar empleado"}
          </Button>
          <Row>
            <Col sm="12">
              <MaterialTable
                columns={[
                  {
                    title: "Mano de obra",
                    field: "employeeName",
                    headerStyle: { fontWeight: "bold" },
                  },
                  {
                    title: "Tiempo",
                    field: "timers",
                    headerStyle: { fontWeight: "bold" },
                  },
                ]}
                data={data}
                title=" "
                options={materialTable.options}
                localization={materialTable.localization}
                editable={{
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataDelete = [...data];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        setData([...dataDelete]);

                        resolve();
                      }, 1000);
                    }),
                }}
                actions={[
                  {
                    icon: () => <FontAwesomeIcon icon={faPen} />,
                    tooltip: "Editar",
                    onClick: (event, rowData) => {
                      handleUpdate(rowData);
                    },
                  },
                ]}
              />
            </Col>
          </Row>
        </Form>
        {data.length > 0 && !workProgress && !isCreateProgress && (
          <Button onClick={handleSubmit}>
            {isUpdate ? "Actualizar ejecución" : "Guardar"}
          </Button>
        )}
        {data.length > 0 && workProgress && (
          <Button onClick={handleUpdateWorkProgress}>Actualizar avance</Button>
        )}
        {data.length > 0 && !workProgress && isCreateProgress && (
          <Button onClick={createWorkProgress}>Crear avance</Button>
        )}
        {isUpdate && (
          <>
            <Button variant="danger" onClick={handleReset}>
              Cancelar
            </Button>
          </>
        )}
        {workProgress && (
          <Button variant="danger" onClick={handleReset}>
            Cancelar
          </Button>
        )}
        <Row>
          <Col sm="12">
            <MaterialTable
              columns={[
                {
                  title: "Item",
                  field: "item",
                  headerStyle: { fontWeight: "bold" },
                },
                {
                  title: "Orden de serivicio",
                  field: "orderOfServiceName",
                  headerStyle: { fontWeight: "bold" },
                },
                {
                  title: "Fecha inicial",
                  field: "date1",
                  headerStyle: { fontWeight: "bold" },
                },
                {
                  title: "Fecha final",
                  field: "date2",
                  headerStyle: { fontWeight: "bold" },
                },
                {
                  title: "Actividad",
                  field: "activitiesItem",
                  headerStyle: { fontWeight: "bold" },
                },
                {
                  title: "Unidad",
                  field: "unitName",
                  headerStyle: { fontWeight: "bold" },
                },
                {
                  title: "Cantidad",
                  field: "amount",
                  headerStyle: { fontWeight: "bold" },
                },
              ]}
              data={dataWorkProgress}
              title={`Avance de obra`}
              options={materialTable.options}
              localization={materialTable.localization}
              actions={[
                // {
                //   icon: () => <FontAwesomeIcon icon={faPlus} />,
                //   tooltip: "Añadir",
                //   onClick: (event, rowData) => {
                //     handleLoadCreateProgress(rowData);
                //   },
                // },
                {
                  icon: () => <FontAwesomeIcon icon={faPen} />,
                  tooltip: "Editar",
                  onClick: (event, rowData) => {
                    handleLoadWorkProgress(rowData);
                  },
                },
                // {
                //   icon: () => <FontAwesomeIcon icon={faTrash} />,
                //   tooltip: "Eliminar",
                //   onClick: (event, rowData) => deleteRow(rowData),
                // },
              ]}
            />
          </Col>
        </Row>
      </Card.Body>
      <Modal size="lg" show={show} onHide={showModal} centered>
        <Modal.Body>
          <Carousel>
            {form.image.length > 0 ? (
              form.image.map((item) => (
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={`${baseURL}${item.url.slice(1)}`}
                    alt="image"
                  />
                </Carousel.Item>
              ))
            ) : (
              <Carousel.Item>
                {/* <img
                  className="d-block w-100"
                  src={`${baseURL}${item.url.slice(1)}`}
                  alt="image"
                /> */}
                <h3>Sin imagenes</h3>
              </Carousel.Item>
            )}
          </Carousel>
        </Modal.Body>
      </Modal>
    </>
  );
}
