import React, { useState, useEffect } from "react";
import { Card, Tabs, Tab, Spinner } from "react-bootstrap";
import Users from "./users";
import Roles from "./roles";
import Charges from "./charges";
import Permissions from "./permissions";
import "../../css/global.css";
import { useSelector } from "react-redux";
import apiStrapi from "../../utils/api/apiStrapi";
import UsersInactive from "./usersInactive";

export default function Membership() {
  const [key, setKey] = useState("users");
  const [loading, setLoading] = useState(true);
  const user = useSelector(({ user }) => user.value);

  /////////Permisos del usuario /////////////////////
  const [users, setUsers] = useState(false);
  const [roles, setRoles] = useState(false);
  const [permissions, setPermissions] = useState(false);
  const [charges, setCharges] = useState(false);
  const namePermissionUsers = 7;
  const namePermissionRoles = 8;
  const namePermissionPermissions = 9;
  const namePermissionCharges = 29;
  ////////////////////////////////////////////////////

  const validatedPermissions = async () => {
    try {
      const result = await apiStrapi.get("permissions?_limit=-1");
      if (result.data.length) {
        const result1 = result.data.some(
          (item) =>
            item.namePermissionId.id == namePermissionUsers &&
            item.roleId.id == user.roleId.id
        );

        if (result1) setUsers(true);

        const result2 = result.data.some(
          (item) =>
            item.namePermissionId.id == namePermissionRoles &&
            item.roleId.id == user.roleId.id
        );

        if (result2) setRoles(true);
        const result3 = result.data.some(
          (item) =>
            item.namePermissionId.id == namePermissionPermissions &&
            item.roleId.id == user.roleId.id
        );

        if (result3) setPermissions(true);
        const result4 = result.data.some(
          (item) =>
            item.namePermissionId.id == namePermissionCharges &&
            item.roleId.id == user.roleId.id
        );
        if (result4) setCharges(true);

        if (result1) setKey("users");
        else if (result2) setKey("roles");
        else if (result3) setKey("permissions");
        else if (result4) setKey("charges");

        setLoading(false);
      }
    } catch (error) {
      console.log(`error.message`, error.message);
    }
  };

  useEffect(() => {
    validatedPermissions();
  }, []);

  return (
    <>
      <div className="content fluid">
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="grow" role="status">
              <span className="visually-hidden"></span>
            </Spinner>
          </div>
        ) : (
          <Card>
            <Tabs
              style={{
                fontSize: 15,
                margin: 2,
                backgroundColor: "",
                color: "#252422",
                fontWeight: "bold",
              }}
              className="m-3"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              unmountOnExit={true}
              mountOnEnter={true}
            >
              {users && (
                <Tab eventKey="users" title="Usuarios" tabClassName="colorTab">
                  <Users />
                </Tab>
              )}
              {users && (
                <Tab
                  eventKey="usersInactive"
                  title="Usuarios inactivos"
                  tabClassName="colorTab"
                >
                  <UsersInactive />
                </Tab>
              )}
              {roles && (
                <Tab eventKey="roles" title="Roles" tabClassName="colorTab">
                  <Roles />
                </Tab>
              )}
              {permissions && (
                <Tab
                  eventKey="permissions"
                  title="Asignar permisos"
                  tabClassName="colorTab"
                >
                  <Permissions />
                </Tab>
              )}
              {charges && (
                <Tab eventKey="charges" title="Cargos" tabClassName="colorTab">
                  <Charges />
                </Tab>
              )}
            </Tabs>
          </Card>
        )}
      </div>
    </>
  );
}
