import { createSlice } from "@reduxjs/toolkit";
import apiStrapi from "utils/api/apiStrapi";
import { setToken, removeData } from "utils/auth";
import { message } from "utils/notification";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: null,
  },
  reducers: {
    login: (state, action) => {
      console.log(`action`, action.payload);
      state.value = action.payload.user;
      state.jwt = action.payload.jwt;
    },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
  },
});

// Action creators are generated for each case reducer function
export const { login } = userSlice.actions;

export const loginAsync = (username, password) => (dispatch) => {
  apiStrapi
    .post("/auth/local", {
      identifier: username,
      password,
    })
    .then((response) => {
      dispatch(login(response.data));
      setToken(response.data.jwt);
      window.location.href = "/admin/wareHouse";
      // Handle success.
      // console.log("Well done!");
      // console.log("User profile", response.data.user);
      // console.log("User token", response.data.jwt);
    })
    .catch((error) => {
      message("Usuario o contraseña son incorrectos", 3);
      console.log("An error occurred:", error.response);
    });
};

export const logoutRequest =
  (redirect = true, redirecUrl = "/login") =>
  (dispatch) => {
    console.log("logout");
    try {
      removeData();
      // setToken({});
      if (redirect) window.location.href = redirecUrl;
    } catch (error) {
      console.log(error.message);
    }
  };

export default userSlice.reducer;
