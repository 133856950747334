import React, { useState, useEffect } from "react";
import { Card, Tabs, Tab, Spinner } from "react-bootstrap";
import Employee from "./employees";
import Clients from "./clients";
import Suplier from "./supplier";
import MaterialsTools from "./materialsTools";
import "../../css/global.css";
import { useSelector } from "react-redux";
import apiStrapi from "../../utils/api/apiStrapi";
import EmployeeInactive from "./employeeInactive";

export default function Home() {
  const [key, setKey] = useState("employee");
  const [loading, setLoading] = useState(true);
  const user = useSelector(({ user }) => user.value);

  /////////Permisos del usuario /////////////////////
  const [employee, setEmployee] = useState(false);
  const [client, setClient] = useState(false);
  const [suplier, setSuplier] = useState(false);
  const [materialsTool, setMaterialTool] = useState(false);
  const namePermissionEmployee = 11;
  const namePermissionClient = 12;
  const namePermissionSuplier = 13;
  const namePermissionMaterialsTool = 14;
  ////////////////////////////////////////////////////

  const validatedPermissions = async () => {
    try {
      const result = await apiStrapi.get("permissions?_limit=-1");
      if (result.data.length) {
        const result1 = result.data.some(
          (item) =>
            item.namePermissionId.id == namePermissionEmployee &&
            item.roleId.id == user.roleId.id
        );

        if (result1) setEmployee(true);

        const result2 = result.data.some(
          (item) =>
            item.namePermissionId.id == namePermissionClient &&
            item.roleId.id == user.roleId.id
        );

        if (result2) setClient(true);
        const result3 = result.data.some(
          (item) =>
            item.namePermissionId.id == namePermissionSuplier &&
            item.roleId.id == user.roleId.id
        );

        if (result3) setSuplier(true);
        const result4 = result.data.some(
          (item) =>
            item.namePermissionId.id == namePermissionMaterialsTool &&
            item.roleId.id == user.roleId.id
        );

        if (result4) setMaterialTool(true);

        if (result1) setKey("employee");
        else if (result2) setKey("clients");
        else if (result3) setKey("suplier");
        else if (result4) setKey("materialsTool");

        setLoading(false);
      }
    } catch (error) {
      console.log(`error.message`, error.message);
    }
  };

  useEffect(() => {
    validatedPermissions();
  }, []);

  return (
    <>
      <div className="content fluid">
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="grow" role="status">
              <span className="visually-hidden"></span>
            </Spinner>
          </div>
        ) : (
          <Card>
            <Tabs
              style={{
                fontSize: 15,
                margin: 2,
                backgroundColor: "",
                color: "#252422",
                fontWeight: "bold",
              }}
              className="m-3"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              unmountOnExit={true}
              mountOnEnter={true}
            >
              {employee && (
                <Tab
                  eventKey="employee"
                  title="Empleados"
                  tabClassName="colorTab"
                >
                  <Employee />
                </Tab>
              )}
              {employee && (
                <Tab
                  eventKey="employeeInactive"
                  title="Empleados inactivos"
                  tabClassName="colorTab"
                >
                  <EmployeeInactive />
                </Tab>
              )}
              {client && (
                <Tab
                  eventKey="clients"
                  title="Clientes"
                  tabClassName="colorTab"
                >
                  <Clients />
                </Tab>
              )}
              {suplier && (
                <Tab
                  eventKey="suplier"
                  title="Proveedor"
                  tabClassName="colorTab"
                >
                  <Suplier />
                </Tab>
              )}
              {materialsTool && (
                <Tab
                  eventKey="materialsTools"
                  title="Productos"
                  tabClassName="colorTab"
                >
                  <MaterialsTools />
                </Tab>
              )}
            </Tabs>
          </Card>
        )}
      </div>
    </>
  );
}
