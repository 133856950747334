import React, { useState, useEffect } from "react";
import { Card, Tabs, Tab, Spinner } from "react-bootstrap";
import Planning from "./planning/planning";
import Execution from "./execution/execution";
import WorkProgress from "./workProgress";
import "../../css/global.css";
import { useSelector } from "react-redux";
import apiStrapi from "../../utils/api/apiStrapi";

export default function Home() {
  const [key, setKey] = useState("planning");
  const [loading, setLoading] = useState(true);
  const user = useSelector(({ user }) => user.value);

  /////////Permisos del usuario /////////////////////
  const [planning, setPlanning] = useState(false);
  const [execution, setExecution] = useState(false);
  const [workProgress, setWorkProgress] = useState(false);
  const namePermissionPlanning = 19;
  const namePermissionExecution = 20;
  const namePermissionWorkProgress = 21;
  ////////////////////////////////////////////////////

  const validatedPermissions = async () => {
    try {
      const result = await apiStrapi.get("permissions?_limit=-1");
      if (result.data.length) {
        const result1 = result.data.some(
          (item) =>
            item.namePermissionId.id == namePermissionPlanning &&
            item.roleId.id == user.roleId.id
        );

        if (result1) setPlanning(true);

        const result2 = result.data.some(
          (item) =>
            item.namePermissionId.id == namePermissionExecution &&
            item.roleId.id == user.roleId.id
        );

        if (result2) setExecution(true);
        const result3 = result.data.some(
          (item) =>
            item.namePermissionId.id == namePermissionWorkProgress &&
            item.roleId.id == user.roleId.id
        );

        if (result3) setWorkProgress(true);

        if (result1) setKey("planning");
        else if (result2) setKey("execution");
        else if (result3) setKey("workProgress");
        
        setLoading(false);
      }
    } catch (error) {
      console.log(`error.message`, error.message);
    }
  };

  useEffect(() => {
    validatedPermissions();
  }, []);

  return (
    <>
      <div className="content fluid">
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="grow" role="status">
              <span className="visually-hidden"></span>
            </Spinner>
          </div>
        ) : (
          <Card>
            <Tabs
              style={{
                fontSize: 15,
                margin: 2,
                backgroundColor: "",
                color: "#252422",
                fontWeight: "bold",
              }}
              className="m-3"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              unmountOnExit={true}
              mountOnEnter={true}
            >
              {planning && (
                <Tab
                  eventKey="planning"
                  title="Planeación"
                  tabClassName="colorTab"
                >
                  <Planning />
                </Tab>
              )}
              {execution && (
                <Tab
                  eventKey="execution"
                  title="Ejecución"
                  tabClassName="colorTab"
                >
                  <Execution />
                </Tab>
              )}
              {workProgress && (
                <Tab
                  eventKey="workProgress"
                  title="Avance de obra"
                  tabClassName="colorTab"
                >
                  <WorkProgress />
                </Tab>
              )}
            </Tabs>
          </Card>
        )}
      </div>
    </>
  );
}
