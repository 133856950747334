import React, { useState, useEffect } from "react";
import apiStrapi from "../../../utils/api/apiStrapi";
import { baseURL } from "../../../utils/api/apiStrapi";
import { message, customConfirm } from "../../../utils/notification";
import { Card, Button, Modal, Form, Row, Col } from "react-bootstrap";
import CreateActitities from "./createActivities";
import "../../../css/global.css";
import excelLogo from "../../../assets/img/excel-icon.png";
const XLSX = require("xlsx");

const initialState = {
  orderOfServiceId: "",
};

const initialItems = {
  item: "",
  description: "",
  unitId: "",
  amount: "",
  valueUnit: "",
  valueTotal: "",
  unitName: "",
};

const options = {
  filtering: false,
  grouping: false,
  exportButton: true,
  actionsColumnIndex: -1,
  pageSize: 30,
  paging: false,
  emptyRowsWhenPaging: false,
  search: false,
  sorting: false,
  toolbar: false,
  padding: "dense",

  // rowStyle: {
  //   width: 10,
  // },
};

const columns = [
  {
    title: "Codigo",
    field: "code",
    headerStyle: { fontWeight: "bold" },
  },
  { title: "Nombre", field: "name", headerStyle: { fontWeight: "bold" } },
  {
    title: "Numero de parte",
    field: "reference",
    headerStyle: { fontWeight: "bold" },
  },
  {
    title: "Cantidad",
    field: "amount",
    type: "numeric",
    headerStyle: { fontWeight: "bold" },
  },
];

export default function Activities() {
  const [activities, setActivities] = useState([]);
  const [form, setForm] = useState({ ...initialState });
  const [items, setItems] = useState({ ...initialItems });
  const [orderOfServices, setOrderOfServices] = useState([]);
  const [units, setUnits] = useState([]);
  const [data, setData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [validatedItems, setValidatedItems] = useState(false);
  const [isUpdateItem, setIsUpdateItem] = useState(false);
  const [show, setShow] = useState(false);
  const [file, setFile] = useState([]);
  const [activitiesJson, setActivitiesJson] = useState([]);
  const [guie, setGuie] = useState();
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleChangeItems = (e) => {
    setItems({ ...items, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form.orderOfServiceId == "") {
      message("Seleccione una orden de servicio", 2);
    } else {
      if (isUpdate) {
        setLoading(true);
        activities.length &&
          activities.forEach(async (item) => {
            if (item.orderOfServiceId == form.orderOfServiceId) {
              await apiStrapi.delete(`activities/${item.id}`);
            }
          });
      }
      data.length &&
        data.map(async (item) => {
          const model = {
            orderOfServiceId: form.orderOfServiceId,
            description: item.description,
            item: item.item,
            unitId: item.unitId,
            amount: item.amount,
            valueUnit: item.valueUnit,
            valueTotal: item.amount * item.valueUnit,
          };
          console.log("model", model);
          try {
            const result = await apiStrapi.post("activities", model);
            if (result) {
              isUpdate
                ? message("Actualizado con exito", 1)
                : message("Guardado con exito", 1);
              handleReset();
            }
          } catch (error) {
            console.log(error.message);
          }
        });
    }
  };

  const loadActivities = async () => {
    try {
      const result = await apiStrapi.get("activities?_limit=-1&_sort=item:asc");
      if (result.data) {
        result.data.map((item) => {
          const orderOfServiceId =
            item.orderOfServiceId != null ? item.orderOfServiceId.id : null;
          const orderOfServiceName =
            item.orderOfServiceId != null ? item.orderOfServiceId.nameOs : null;

          const unitId = item.unitId != null ? item.unitId.id : null;
          const unitName = item.unitId != null ? item.unitId.name : null;
          const valueTotalNew =
            item.valueTotal != null
              ? formatterPeso.format(item.valueTotal).replace(" ", "")
              : null;
          const valueUnitNew =
            item.valueUnit != null
              ? formatterPeso.format(item.valueUnit).replace(" ", "")
              : null;
          item.orderOfServiceId = orderOfServiceId;
          item.orderOfServiceName = orderOfServiceName;
          item.unitId = unitId;
          item.unitName = unitName;
          item.valueTotalNew = valueTotalNew;
          item.valueUnitNew = valueUnitNew;
        });
        console.log(`result.data`, result.data);
      }
      result.data ? setActivities(result.data) : setActivities([]);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadUnits = async () => {
    try {
      const result = await apiStrapi.get("units");
      result.data ? setUnits(result.data) : setUnits([]);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadOrderOfServices = async () => {
    try {
      const result = await apiStrapi.get("order-of-services?_limit=-1");
      if (result.data) {
        const data = result.data
          .map((item) => item.statusesId.id == 1 && item)
          .filter((item) => item != false);
        if (data.length) setOrderOfServices(data);
      }
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const formatterPeso = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });

  const addItems = (e) => {
    e.preventDefault();
    const formControl = e.currentTarget;
    const isValid = formControl.checkValidity() !== false;
    setValidatedItems(true);
    if (isValid) {
      const base =
        data.length &&
        data
          .map((item) => item.id != items.id && item)
          .filter((i) => i != false);
      items.valueTotal =
        parseInt(items.valueUnit, 10) * parseInt(items.amount, 10);
      const unitName =
        units.length &&
        units
          .map((item) => item.id == items.unitId && item.name)
          .filter((item) => item != false);
      items.unitName = unitName[0];

      if (base.length > 0) {
        setData([...base, items]);
      } else {
        setData([...data, items]);
      }
      setItems({ ...initialItems });
      setValidatedItems(false);
      setIsUpdateItem(false);
    }
  };

  const itemsQuery = (orderOfServiceId) => {
    if (orderOfServiceId) {
      const data =
        activities.length &&
        activities
          .map((item) => item.orderOfServiceId == orderOfServiceId && item)
          .filter((item) => item != false);
      if (data.length) {
        setIsUpdate(true);
        setData(data);
      } else {
        setData([]);
        setIsUpdate(false);
      }
    } else {
      setData([]);
    }
  };

  const handleUpdate = (rowData) => {
    setIsUpdateItem(true);
    const dataUpdate =
      data.length &&
      data
        .map((item) => {
          if (item.id == rowData.id) {
            return item;
          }
        })
        .filter((item) => item != null);
    setItems(dataUpdate[0]);
  };

  const deleteRow = async (orderOfServiceId) => {
    try {
      customConfirm(
        `Esta seguro que sea eliminar todas las actividades relacionadas a esta orden de servicio`
      ).then(async (confirm) => {
        if (confirm) {
          const result =
            activities.length &&
            activities.map(async (item) => {
              if (item.orderOfServiceId == orderOfServiceId) {
                await apiStrapi.delete(`activities/${item.id}`);
              }
            });
          loadActivities();
          if (result.error) {
            message(result.message, 2);
          } else {
            handleReset();
            message(`Eliminado con exito`, 1);
          }
        }
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const noPuntoComa = (event) => {
    var e = event || window.event;
    var key = e.keyCode || e.which;

    if (key === 110 || key === 190 || key === 188) {
      e.preventDefault();
    }
  };

  const loadActivitiesJson = async () => {
    try {
      const result = await apiStrapi.get("activities-jsons");
      console.log(`result`, result);
      if (result.data[0]) setActivitiesJson(result.data[0].data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadFiles = async () => {
    if (file.length > 0) {
      try {
        setLoading(true);
        const json = JSON.stringify(file);
        await apiStrapi.delete("removeAll");
        const saveJson = await apiStrapi.post("activities-jsons", {
          data: json,
        });
        if (saveJson) {
          message("Datos subidos con exito", 1);
          loadActivitiesJson();
          showModal();
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      message("El formato que intenta subir no es igual al de la guia", 2);
    }
  };

  const readExcel = (file) => {
    if (file ? file : []) {
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type: "buffer" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws);
          resolve(data);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
      promise.then((d) => {
        setFile(d);
      });
    }
  };

  const loadInputItems = async (item) => {
    if (item) {
      const result =
        activitiesJson.length &&
        activitiesJson.filter((element) => item == element.item && element);
      if (result.length > 0) {
        const data = await apiStrapi.get(
          `units?_where[name]=${result[0].unidad}`
        );
        setItems({
          ...items,
          description: result[0].descripcion,
          unitId: data.data[0]?.id,
          valueUnit: result[0].valorUnidad,
          amount: result[0].cantidad,
        });
      } else {
        setItems({
          ...items,
          description: "",
          unitId: "",
          amount: "",
          valueUnit: "",
          valueTotal: "",
          unitName: "",
        });
      }
    }
  };

  const showModal = () => setShow(!show);

  const loadGuieJson = async () => {
    try {
      const result = await apiStrapi.get("guia-jsons");
      if (result.data) {
        const guie = result.data[0].file[0].url.substring(1);
        setGuie(guie);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleReset = () => {
    loadActivities();
    setForm({ ...initialState });
    setItems({ ...initialItems });
    setValidatedItems(false);
    setIsUpdate(false);
    setData([]);
  };

  useEffect(() => {
    loadActivitiesJson();
    loadActivities();
    loadUnits();
    loadOrderOfServices();
    loadGuieJson();
  }, []);

  useEffect(() => {
    loadInputItems(items.item);
  }, [items.item]);

  useEffect(() => {
    itemsQuery(form.orderOfServiceId);
  }, [form.orderOfServiceId]);

  return (
    <>
      <Card.Body>
        <CreateActitities
          handleSubmit={handleSubmit}
          form={form}
          items={items}
          handleChange={handleChange}
          handleChangeItems={handleChangeItems}
          units={units}
          addItems={addItems}
          data={data}
          validatedItems={validatedItems}
          handleReset={handleReset}
          options={options}
          orderOfServices={orderOfServices}
          setData={setData}
          columns={columns}
          isUpdate={isUpdate}
          deleteRow={deleteRow}
          handleUpdate={handleUpdate}
          isUpdateItem={isUpdateItem}
          noPuntoComa={noPuntoComa}
          showModal={showModal}
        />
      </Card.Body>

      <Modal size="lg" show={show} onHide={showModal} centered>
        <Modal.Header style={{ backgroundColor: "#0F1D37", color: "white" }}>
          <p className="h4" htmlFor="exampleFile">
            Actualizar guia de actividades
          </p>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm="6">
              <div className="d-flex justify-content-left align-items-center">
                <Form.Group>
                  <h5>Adjuntar excel</h5>
                  <Form.File
                    className="mt-57"
                    name="file"
                    id="fileExcel"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      readExcel(file);
                    }}
                  />
                </Form.Group>
              </div>
            </Col>
            <Col sm="6">
              <div className="d-flex justify-content-left align-items-center">
                <img src={excelLogo} alt="" />
                <br />
                <a href={`${baseURL}${guie}`} className="mx-3" download={true}>
                  Descargar guia
                </a>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={loadFiles}>
            {loading ? "Cargando..." : "Cargar"}
          </Button>
          <Button variant="danger" onClick={showModal}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
