export const formatDataReportByOs = (items, data, meterials) => {
  return items?.reduce((array, item) => {
    if (item.items.length) {
      item.items.forEach((i) => {
        const obj = data.find(
          (e) => e.id == (item?.entrieId?.id || item?.exitId?.id)
        );
        const materiaT = meterials?.find((o) => o.id == i.materialToolsId);
        if (obj && materiaT) {
          const model = {
            id: obj.id,
            date: obj.date,
            orderOfServiceId: {
              id: obj.orderOfServiceId.id,
              nameOs: obj.orderOfServiceId.nameOs,
              numberOs: obj.orderOfServiceId.numberOs,
              centerOfCost: obj.orderOfServiceId.centerOfCost,
              durationOs:
                obj.orderOfServiceId.durationOs != undefined
                  ? obj.orderOfServiceId.durationOs
                  : 0,
              valueOs: parseInt(obj.orderOfServiceId.value, 10),
            },
            amount: parseInt(i.amount, 10),
            description: obj.description,
            materialToolsId: {
              id: materiaT.id,
              code: materiaT.code,
              name: materiaT.name,
              reference: materiaT.reference,
              value: parseInt(materiaT.value, 10),
            },
            image: obj.image,
            MTCode: i.code,
            clientId: obj.orderOfServiceId.clientId,
          };
          array.push(model);
        }
      });
    }
    return array;
  }, []);
};
