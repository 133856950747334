import React, { useState, useEffect } from "react";
import { Card, Tabs, Tab, Spinner } from "react-bootstrap";
import "../../css/global.css";
import { useSelector } from "react-redux";
import apiStrapi from "../../utils/api/apiStrapi";
import WorkProgressReport from "./workProgressReport";
import ReportStockByOs from "./reportStockByOs";
import ReportPandG from "../reports/reportPandG";
import ReportGeneral from "../reports/ReportGeneral";

export default function Home() {
  const [key, setKey] = useState("workProgressReport");
  const user = useSelector(({ user }) => user.value);
  const [loading, setLoading] = useState(true);

  /////////Permisos del usuario /////////////////////
  const [workProgress, setWorkProgress] = useState(false);
  const [toolsByOs, setToolsByOs] = useState(false);
  const [reportPandG, setReportPandG] = useState(false);
  const [reportGeneral, setReportGeneral] = useState(false);
  const namePermissionReportPandG = 26;
  const namePermissionWorkProgress = 23;
  const namePermissionToolsByOs = 24;
  const namePermissionReportGeneral = 28;
  ////////////////////////////////////////////////////

  const validatedPermissions = async () => {
    try {
      const result = await apiStrapi.get("permissions?_limit=-1");
      if (result.data.length) {
        const result1 = result.data.some(
          (item) =>
            item.namePermissionId.id == namePermissionWorkProgress &&
            item.roleId.id == user.roleId.id
        );
        if (result1) setWorkProgress(true);

        const result2 = result.data.some(
          (item) =>
            item.namePermissionId.id == namePermissionToolsByOs &&
            item.roleId.id == user.roleId.id
        );

        if (result2) setToolsByOs(true);

        const result3 = result.data.some(
          (item) =>
            item.namePermissionId.id == namePermissionReportPandG &&
            item.roleId.id == user.roleId.id
        );

        if (result3) setReportPandG(true);

        const result4 = result.data.some(
          (item) =>
            item.namePermissionId.id == namePermissionReportGeneral &&
            item.roleId.id == user.roleId.id
        );

        if (result4) setReportGeneral(true);

        if (result1) setKey("workProgressReport");
        else if (result2) setKey("reportStockByOs");
        else if (result3) setKey("reportPandG");
        else if (result4) setKey("reportGeneralG");
        
        setLoading(false);
      }
    } catch (error) {
      console.log(`error.message`, error.message);
    }
  };

  useEffect(() => {
    validatedPermissions();
  }, []);

  return (
    <>
      <div className="content fluid">
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="grow" role="status">
              <span className="visually-hidden"></span>
            </Spinner>
          </div>
        ) : (
          <Card>
            <Tabs
              style={{
                fontSize: 15,
                margin: 2,
                color: "#252422",
                fontWeight: "bold",
              }}
              className="m-3"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              unmountOnExit={true}
              mountOnEnter={true}
            >
              {workProgress && (
                <Tab
                  eventKey="workProgressReport"
                  title="Avance de obra"
                  tabClassName="colorTab"
                >
                  <WorkProgressReport />
                </Tab>
              )}
              {toolsByOs && (
                <Tab
                  eventKey="reportStockByOs"
                  title="Materiales por OT"
                  tabClassName="colorTab"
                >
                  <ReportStockByOs />
                </Tab>
              )}
              {reportPandG && (
                <Tab
                  eventKey="reportPandG"
                  title="Reporte P&G"
                  tabClassName="colorTab"
                >
                  <ReportPandG />
                </Tab>
              )}
              {reportGeneral && (
                <Tab
                  eventKey="reportGeneralG"
                  title="Reporte General"
                  tabClassName="colorTab"
                >
                  <ReportGeneral />
                </Tab>
              )}
            </Tabs>
          </Card>
        )}
      </div>
    </>
  );
}
