import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import "../../../css/global.css";
import "../../../css/materialTable.css";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialTable from "material-table";
import materialTable from "../../../utils/materialTableUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faClipboard } from "@fortawesome/free-solid-svg-icons";

export default function CreateReturn(props) {
  return (
    <Form
      noValidate={true}
      className={props.validated ? "was-validated" : ""}
      onSubmit={props.handleSubmit}
    >
      <Row>
        <Col sm="3">
          <Form.Group>
            <p className="labelStyle">Operación *</p>
            <Form.Control
              as="select"
              name="operationTypeId"
              value={props.form.operationTypeId}
              onChange={props.handleChange}
              required
            >
              <option value="">Seleccione</option>
              {props.operation &&
                props.operation.map(
                  (item) =>
                    item.id != 1 && (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    )
                )}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm="3">
          <Form.Group>
            <p className="labelStyle">Empleado que entrega *</p>
            <Form.Control
              as="select"
              name="employeeId"
              value={props.form.employeeId}
              onChange={props.handleChange}
              required
            >
              <option value="">Seleccione</option>
              {props.employees &&
                props.employees.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.fullName}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm="3">
          <Form.Group>
            <p className="labelStyle">Orden de Trabajo *</p>
            <Form.Control
              as="select"
              name="orderOfServiceId"
              value={props.form.orderOfServiceId}
              onChange={props.handleChange}
              placeholder="Producto"
              required
            >
              <option value="">Seleccione</option>
              {props.orderOfServices &&
                props.orderOfServices.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.numberOs} - {item.nameOs}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm="3">
          <Form.Group>
            <p className="labelStyle">Fecha *</p>
            <Form.Control
              type="date"
              name="date"
              value={props.form.date}
              onChange={props.handleChange}
              placeholder="Fecha"
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <Form.Group>
            <p className="labelStyle">Descripción/Remisión/Factura</p>
            <Form.Control
              as="textarea"
              name="description"
              value={props.form.description}
              onChange={props.handleChange}
              placeholder="Descripción/Remisión/Factura"
            />
          </Form.Group>
        </Col>
        <Col sm="6">
          <Form.Group controlId="formFile" className="mb-3">
            <p className="labelStyle">Imagen</p>
            <Form.Control
              onChange={props.handleChangeFile}
              name="image"
              type="file"
            />
          </Form.Group>
        </Col>
      </Row>

      <div>
        <Row className="justify-content-between">
          <Col sm="3">
            <Form.Group>
              <p className="labelStyle">Nombre *</p>
              <Autocomplete
                options={props.list}
                value={props.inputValue}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={(e, v) => {
                  props.setItems({
                    ...props.items,
                    materialToolsName: v.name != null ? v.name : "",
                    materialToolsId: v.id != null ? v.id : "",
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" fullWidth />
                )}
              />
            </Form.Group>
          </Col>
          <Col sm="2">
            <Form.Group>
              <p className="labelStyle">Codigo</p>
              <Form.Control
                type="text"
                name="code"
                value={props.items.code}
                onChange={(e) =>
                  props.setItems({ ...props.items, code: e.target.value })
                }
                disabled
              />
            </Form.Group>
          </Col>
          <Col sm="2">
              <Form.Group>
                <p className="labelStyle">Numero de parte</p>
                <Form.Control
                  type="text"
                  name="reference"
                  value={props.items.reference}
                  onChange={(e) =>
                    props.setItems({
                      ...props.items,
                      reference: e.target.value,
                    })
                  }
                  placeholder="Numero de parte"
                  disabled
                />
              </Form.Group>
            </Col>
            <Col sm="2">
              <Form.Group>
                <p className="labelStyle">Numero de serial</p>
                <Form.Control
                  type="text"
                  name="numberOfReference"
                  value={props.items.numberOfReference}
                  onChange={(e) =>
                    props.setItems({
                      ...props.items,
                      numberOfReference: e.target.value,
                    })
                  }
                  placeholder="Numero de serial"
                  disabled
                />
              </Form.Group>
            </Col>
          <Col sm="2">
            <Form.Group>
              <p className="labelStyle">Cantidad</p>
              <Form.Control
                type="number"
                name="amount"
                onChange={(e) =>
                  props.setItems({ ...props.items, amount: e.target.value })
                }
                value={props.items.amount}
                min="1"
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
      <Button size="sm" variant="outline-success" onClick={props.addItems}>
        {props.isUpdateItem ? "Actualizar item" : "Agregar item"}
      </Button>
      <table className="table table-condensed " id="customers">
        <thead>
          <tr>
            <th scope="col">Codigo</th>
            <th scope="col">Nombre</th>
            <th scope="col">Referencia</th>
            <th scope="col">Cantidad</th>
            <th scope="col">Opciones</th>
          </tr>
        </thead>
        <tbody>
          {props.data.length > 0 &&
            props.data.map((item) => (
              <tr key={item.code}>
                <td>{item.code}</td>
                <td>{item.name}</td>
                <td>{item.reference}</td>
                <td>{item.amount}</td>
                <td className="options-table" width="8%">
                  <div className="d-flex justify-content-around">
                    <FontAwesomeIcon
                      icon={faPen}
                      onClick={() => {
                        props.setItems(item);
                        props.setIsUpdateItem(true);
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => {
                        props.handleDeleteItems(item);
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {props.data.length > 0 && <Button type="submit">Guardar</Button>}
    </Form>
  );
}
