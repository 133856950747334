import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import MaterialTable from "material-table";
import materialTable from "../../utils/materialTableUtils";
import "../../css/global.css";
import apiStrapi from "../../utils/api/apiStrapi";
import { formatDataInventary } from "utils/formatDataInvertary";
const initialState = {};

const options = {
  // // headerStyle: { backgroundColor: '#253053', color: 'white' },

  filtering: false,
  grouping: false,
  exportButton: true,
  actionsColumnIndex: -1,
  pageSize: 1000,
  paging: true,
  pageSizeOptions: [5, 10, 20, 50, 100, 500, 1000],
  emptyRowsWhenPaging: false,
  search: true,
  sorting: false,
  toolbar: true,
  padding: "dense",
};

export default function StockReport() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    try {
      setLoading(true);
      const materials = await apiStrapi.get("material-tools?_limit=-1");
      const itemsOfExits = await apiStrapi.get("items-of-exits?_limit=-1");
      const dataExits = await apiStrapi.get("exits?_limit=-1");
      const itemsOfEntries = await apiStrapi.get("items-of-entries?_limit=-1");
      const dataEmtries = await apiStrapi.get("entries?_limit=-1");
      const returns = await apiStrapi.get("returns?_limit=-1");

      const entries = formatDataInventary(
        itemsOfEntries.data,
        dataEmtries.data,
        materials.data,
        "entry"
      );

      const exits = formatDataInventary(
        itemsOfExits.data,
        dataExits.data,
        materials.data,
        "exit"
      );

      setData(
        materials.data.map((item) => {
          let amountEntry = 0;
          let amountReturn = 0;
          let amountExit = 0;

          entries?.forEach((e) => {
            if (item.code == e.materialToolsId.code)
              amountEntry = amountEntry + parseInt(e.amount);
          });
          returns.data?.forEach((e) => {
            if (item.code == e.materialToolsId.code)
              amountReturn = amountReturn + parseInt(e.amount);
          });
          exits?.forEach((e) => {
            if (item.code == e.materialToolsId.code)
              amountExit = amountExit + parseInt(e.amount);
          });

          item.amount = amountEntry + amountReturn - amountExit;
          return item;
        })
      );
      setLoading(false);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  useEffect(() => {
    loadData();
  }, []);
  
  return (
    <>
      <Card.Header>
        <h2 className="titleHeader">Inventario de Materiales/Herramientas</h2>
      </Card.Header>
      <Card.Body>
        <MaterialTable
          columns={[
            {
              title: "Codigo",
              field: "code",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Categoria",
              field: "category.name",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Nombre del item",
              field: "name",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Numero de parte",
              field: "reference",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Numero de serial",
              field: "numberOfReference",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Unidad",
              field: "unitId.name",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Stock",
              field: "amount",
              headerStyle: { fontWeight: "bold" },
            },
          ]}
          isLoading={loading}
          options={options}
          localization={materialTable.localization}
          data={data}
          title="Registros de inventario"
        />
      </Card.Body>
    </>
  );
}
