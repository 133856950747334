const localization = {
  pagination: {
    firstTooltip: "Primera pagina",
    previousTooltip: "Anterior",
    nextTooltip: "Siguiente",
    lastTooltip: "Ultima pagina",
    labelRowsSelect: "Registros",
    labelDisplayedRows: "{from}-{to} de {count}",
  },
  toolbar: {
    searchPlaceholder: "Busqueda",
  },
  header: {
    actions: "Opciones",
  },
  grouping: {
    groupedBy: "Agrupado por:",
    placeholder: "Arrastre aquí la columna para agruparla",
  },
  body: {
    emptyDataSourceMessage: "Sin datos",
    filterRow: {
      filterTooltip: "Filtro",
    },
    editRow: {
      saveTooltip: "Guardar",
      cancelTooltip: "Cancelar",
      deleteText: "¿Estás segur@ de que quieres eliminar esta fila?",
    },
    addTooltip: "añadir",
    deleteTooltip: "Eliminar",
    editTooltip: "Editar",
    bulkEditTooltip: "Editar todo",
    bulkEditApprove: "Guardar todos los cambios",
    bulkEditCancel: "Descartar todos los cambios",
  },
};

const options = {
  // // headerStyle: { backgroundColor: '#253053', color: 'white' },

  filtering: false,
  grouping: false,
  exportButton: true,
  actionsColumnIndex: -1,
  pageSize: 1000,
  paging: true,
  pageSizeOptions: [5, 10, 20, 50, 100, 200, 300, 400, 500, 1000, 3000],
  emptyRowsWhenPaging: false,
  search: true,
  sorting: true,
  toolbar: true,
  padding: "dense",
};

export default {
  localization,
  options,
};
