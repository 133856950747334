import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import "../../css/global.css";
import MaterialTable from "material-table";
import materialTable from "../../utils/materialTableUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { message, customConfirm } from "../../utils/notification";
import apiStrapi from "../../utils/api/apiStrapi";

const initialState = {
  numberOs: "",
  nameOs: "",
  clientId: "",
  statusesId: 1,
  value: "",
  employeeId: "",
  centerOfCost: "",
  description: "",
  duration: "",
};

export default function OrderOfServices() {
  const [form, setForm] = useState({ ...initialState });
  const [isUpdate, setIsUpdate] = useState(false);
  const [list, setList] = useState([]);
  const [client, setClient] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [validated, setValidated] = useState(false);

  const loadData = async () => {
    try {
      const result = await apiStrapi.get("order-of-services?_limit=-1");
      const data = result.data;
      data.map((item) => {
        const clientName = item.clientId != null ? item.clientId.name : null;
        const clientId = item.clientId != null ? item.clientId.id : null;
        const statusesId = item.statusesId != null ? item.statusesId.id : null;
        const statusesName =
          item.statusesId != null ? item.statusesId.name : null;
        const employeeName =
          item.employeeId != null ? item.employeeId.fullName : null;
        const employeeId = item.employeeId != null ? item.employeeId.id : null;
        item.clientName = clientName;
        item.clientId = clientId;
        item.statusesId = statusesId;
        item.statusesName = statusesName;
        item.employeeName = employeeName;
        item.employeeId = employeeId;
        item.valueFormat = item.value ? formatterPeso.format(item.value) : "";
      });
      if (data) setList(data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadClient = async () => {
    try {
      const result = await apiStrapi.get("clients");
      const data = result.data;
      if (data) setClient(data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadEmployees = async () => {
    try {
      const result = await apiStrapi.get("employees");
      result.data ? setEmployees(result.data) : setEmployees([]);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadStatuses = async () => {
    try {
      const result = await apiStrapi.get("statuses");
      const data = result.data;
      if (data) setStatuses(data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formControl = e.currentTarget;
    const isValid = formControl.checkValidity() !== false;
    setValidated(true);
    if (isValid) {
      try {
        isUpdate
          ? (await apiStrapi.put(`order-of-services/${form.id}`, form)) &&
            message("Actualizado con exito", 1)
          : (await apiStrapi.post("order-of-services", form)) &&
            message("Guardado con exito", 1);
        handleReset();
      } catch (error) {
        console.log("Error: ", error.message);
      }
    }
  };

  const deleteRow = async (rowData) => {
    try {
      customConfirm(`Esta seguro que sea eliminar ${rowData.nameOs}`).then(
        async (confirm) => {
          if (confirm) {
            const result = await apiStrapi.delete(
              `order-of-services/${rowData.id}`
            );
            if (result.error) {
              message(result.message, 2);
            } else {
              loadData();
              message(`${rowData.nameOs} Eliminado con exito`, 1);
            }
          }
        }
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  const noPuntoComa = (event) => {
    var e = event || window.event;
    var key = e.keyCode || e.which;

    if (key === 110 || key === 190 || key === 188) {
      e.preventDefault();
    }
  };

  const formatterPeso = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });

  const handleReset = () => {
    loadData();
    setIsUpdate(false);
    setForm({ ...initialState });
    setValidated(false);
  };

  const formatNumberOrg = (num) => {
    if (num) {
      const montoFormat = num.replace(/[^0-9\.]+/g, "");
      return montoFormat;
    }
  };
  useEffect(() => {
    loadData();
    loadClient();
    loadStatuses();
    loadEmployees();
  }, []);

  return (
    <>
      <Card.Body>
        <Form
          noValidate={true}
          className={validated ? "was-validated" : ""}
          onSubmit={handleSubmit}
        >
          <Row>
            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Número de orden *</p>
                <Form.Control
                  type="text"
                  name="numberOs"
                  value={form.numberOs}
                  onChange={handleChange}
                  placeholder="Número de orden"
                  required
                />
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Nombre de la orden *</p>
                <Form.Control
                  type="text"
                  name="nameOs"
                  value={form.nameOs}
                  onChange={handleChange}
                  placeholder="Nombre de la orden"
                  required
                />
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Centro de costo *</p>
                <Form.Control
                  type="text"
                  name="centerOfCost"
                  value={form.centerOfCost}
                  onChange={handleChange}
                  placeholder="Centro de costo"
                  required
                />
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Valor *</p>
                <Form.Control
                  type="number"
                  name="value"
                  value={form.value}
                  onChange={handleChange}
                  placeholder="Valor"
                  onKeyDown={noPuntoComa}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Cliente *</p>
                <Form.Control
                  as="select"
                  name="clientId"
                  value={form.clientId}
                  onChange={handleChange}
                  required
                >
                  <option value="">Seleccione</option>
                  {client &&
                    client.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Supervisor</p>
                <Form.Control
                  as="select"
                  name="employeeId"
                  value={form.employeeId}
                  onChange={handleChange}
                >
                  <option value="">Seleccione</option>
                  {employees &&
                    employees.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.fullName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Estado *</p>
                <Form.Control
                  as="select"
                  name="statusesId"
                  value={form.statusesId}
                  onChange={handleChange}
                  required
                >
                  <option value="">Seleccione</option>
                  {statuses &&
                    statuses.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Duración O.S. *</p>
                <Form.Control
                  type="number"
                  name="duration"
                  value={form.duration}
                  onChange={handleChange}
                  placeholder="Duración"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm="12">
              <Form.Group>
                <p className="labelStyle">Descripción</p>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={form.description}
                  onChange={handleChange}
                  placeholder="Descripción"
                />
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit">
            {isUpdate ? "Actualizar" : "Guardar"}
          </Button>
          {isUpdate && (
            <Button variant="danger" onClick={handleReset}>
              Cancelar
            </Button>
          )}
        </Form>
        <MaterialTable
          columns={[
            {
              title: "Número de la orden",
              field: "numberOs",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Nombre de la orden",
              field: "nameOs",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Centro de costo",
              field: "centerOfCost",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Valor",
              field: "valueFormat",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Cliente",
              field: "clientName",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Supervisor",
              field: "employeeName",
              headerStyle: { fontWeight: "bold" },
            },

            {
              title: "Estado",
              field: "statusesName",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "duración",
              field: "duration",
              headerStyle: { fontWeight: "bold" },
            },
            // {
            //   title: "Descripción",
            //   field: "description",
            //   headerStyle: { fontWeight: "bold" },
            // },
          ]}
          // isLoading={data.loading}
          options={materialTable.options}
          localization={materialTable.localization}
          data={list}
          title="Registros de ordenes de servicio"
          actions={[
            {
              icon: () => <FontAwesomeIcon icon={faPen} />,
              tooltip: "Editar",
              onClick: (event, rowData) => {
                setIsUpdate(true);
                setForm({
                  id: rowData.id,
                  description: rowData.description,
                  centerOfCost: rowData.centerOfCost,
                  numberOs: rowData.numberOs,
                  nameOs: rowData.nameOs,
                  clientId: rowData.clientId,
                  duration: rowData.duration,
                  statusesId: rowData.statusesId,
                  value: rowData.value,
                  employeeId: rowData.employeeId,
                });
              },
            },
            {
              icon: () => <FontAwesomeIcon icon={faTrash} />,
              tooltip: "Eliminar",
              onClick: (event, rowData) => deleteRow(rowData),
            },
          ]}
        />
      </Card.Body>
    </>
  );
}
