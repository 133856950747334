import React, { useState, useEffect } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import MaterialTable from "material-table";
import materialTable from "../../../utils/materialTableUtils";
import { message, customConfirm } from "../../../utils/notification";
import "../../../css/global.css";
import apiStrapi from "../../../utils/api/apiStrapi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

const initialState = {
  orderOfServiceId: "",
  activitiesId: "",
  description: "",
  date: "",
};

const initialFormItems = {
  employeesId: "",
  timers: "",
  employeeName: "",
};

export default function Planning() {
  const [form, setForm] = useState({ ...initialState });
  const [formItems, setFormItems] = useState({ ...initialFormItems });
  const [orderOfServices, setOrderOfServices] = useState([]);
  const [activities, setActivities] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [validated, setValidated] = useState(false);
  const [validatedItems, setValidatedItems] = useState(false);
  const [data, setData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [planning, setPlanning] = useState([]);
  const [personnels, setPersonnels] = useState([]);
  const [isUpdateItem, setIsUpdateItem] = useState(false);
  const [dataItem, setDataItem] = useState([]);

  const loadOrderOfServices = async () => {
    try {
      const result = await apiStrapi.get("order-of-services?_limit=-1");
      if (result.data) {
        const data = result.data
          .map((item) => item.statusesId.id == 1 && item)
          .filter((item) => item != false);
        if (data.length) setOrderOfServices(data);
      }
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleChangeItems = (e) => {
    setFormItems({ ...formItems, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const model = {
        orderOfServiceId: form.orderOfServiceId,
        activitiesId: form.activitiesId,
        date: form.date,
        description: form.description,
      };
      const formControl = e.currentTarget;
      const isValid = formControl.checkValidity() !== false;
      setValidated(true);
      if (isValid) {
        const result = isUpdate
          ? await apiStrapi.put(`plannings/${form.id}`, model)
          : await apiStrapi.post("plannings", model);
        if (isUpdate) {
          personnels.length &&
            personnels.forEach(async (item) => {
              await apiStrapi.delete(`construction-personnels/${item.id}`);
            });
        }
        if (result.data) {
          data.length &&
            data.map(async (item) => {
              const personnelsModel = {
                planningId: result.data.id,
                employeeId: item.employeesId,
                timers: item.timers,
              };
              await apiStrapi.post("construction-personnels", personnelsModel);
            });
          message(
            isUpdate
              ? "Planeación actualizada con exito"
              : "Planeación registrada con exito",
            1
          );
          handleReset();
        }
      }
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const deleteRow = async (id) => {
    try {
      customConfirm(`Esta seguro que sea eliminar esta planeación`).then(
        async (confirm) => {
          if (confirm) {
            const validated = await apiStrapi.get(
              `executions?_where[activitiesId.id]=${form.activitiesId}`
            );
            console.log(`validated`, validated);
            if (validated) {
              console.log(`Esta en ejecucion`);
            } else {
              console.log(`no esta se puede eliminar`);
            }
            //   const result = await apiStrapi.delete(`plannings/${id}`);
            //   if (result.data) {
            //     personnels.forEach(async (item) => {
            //       await apiStrapi.delete(`construction-personnels/${item.id}`);
            //     });
            //   }
            //   loadActivities();
            //   if (result.error) {
            //     message(result.message, 2);
            //   } else {
            //     handleReset();
            //     message(`Eliminado con exito`, 1);
            //   }
          }
        }
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadPlannings = async () => {
    try {
      const result = await apiStrapi.get("plannings?_limit=-1");
      const data = result.data;
      if (data) {
        data.forEach((item) => {
          const orderOfServiceId =
            item.activitiesId != null
              ? item.activitiesId.orderOfServiceId
              : null;
          const activitiesId =
            item.activitiesId != null ? item.activitiesId.id : null;
          const activitiesItem =
            item.activitiesId != null ? item.activitiesId.item : null;
          const ordenOfServiceNumber =
            item.orderOfServiceId != null
              ? item.orderOfServiceId.numberOs
              : null;
          item.ordenOfServiceNumber = ordenOfServiceNumber;
          item.orderOfServiceId = orderOfServiceId;
          item.activitiesId = activitiesId;
          item.activitiesItem = activitiesItem;
        });
        setPlanning(data);
      }
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadEmployees = async () => {
    try {
      const result = await apiStrapi.get("employees?_limit=-1");
      if (result.data) setEmployees(result.data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadActivities = async (orderOfServiceId) => {
    if (orderOfServiceId) {
      try {
        const result = await apiStrapi.get("activities");
        const data =
          result.data &&
          result.data
            .map((item) => item.orderOfServiceId.id == orderOfServiceId && item)
            .filter((item) => item != false);

        if (data) {
          const dataNew = data.map((item) => {
            const model = {
              id: item.id,
              item: item.item,
              description: item.description,
              orderOfServiceId: item.orderOfServiceId.id,
              orderOfServiceName: item.orderOfServiceId.nameOs,
              unitName: item.unitId.name,
              amount: item.amount,
            };
            return model;
          });
          setDataItem(dataNew);
          setActivities(data);
        }
      } catch (error) {
        console.log("Error: ", error.message);
      }
    }
  };

  const addItems = (e) => {
    e.preventDefault();
    const formControl = e.currentTarget;
    const isValid = formControl.checkValidity() !== false;
    setValidatedItems(true);
    if (formItems.timers && formItems.employeesId) {
      const employeeValid =
        data.length &&
        data
          .map((item) => {
            if (item.employeesId == formItems.employeesId) {
              return item;
            }
          })
          .filter((item) => item != undefined);
      if (employeeValid.length && isUpdateItem) {
        console.log("update");
        const base =
          data.length &&
          data
            .map((item) => item.employeesId != formItems.employeesId && item)
            .filter((i) => i != false);
        employees.length &&
          employees.map((item) => {
            if (item.id == formItems.employeesId) {
              setData([...base, { ...formItems, employeeName: item.fullName }]);
              setFormItems({ ...initialFormItems });
              setValidatedItems(false);
              setIsUpdateItem(false);
            }
          });
      }
      if (employeeValid.length && !isUpdateItem)
        message("El empleado ya esta agregado", 2);
      if (!employeeValid.length) {
        console.log("no existe crear");
        setValidatedItems(false);
        employees.length &&
          employees.map((item) => {
            if (item.id == formItems.employeesId) {
              setData([...data, { ...formItems, employeeName: item.fullName }]);
              setFormItems({ ...initialFormItems });
            }
          });
      }
    }
  };
  const loadQuery = async (planningId) => {
    const result = await apiStrapi.get("construction-personnels?_limit=-1");
    const data =
      result.data.length &&
      result.data
        .map((item) => {
          if (item.planningId.id == planningId) {
            const model = {
              id: item.id,
              planningId: item.planningId.id,
              employeesId: item.employeeId.id,
              timers: item.timers,
              employeeName: item.employeeId.fullName,
            };
            return model;
          }
        })
        .filter((item) => item != null);
    if (data.length) setPersonnels(data);
    return data;
  };

  const handleUpdate = (rowData) => {
    setIsUpdateItem(true);
    setFormItems(rowData);
  };

  const loadRegister = async (orderOfServiceId, activitiesId) => {
    if (orderOfServiceId && activitiesId) {
      const result =
        planning.length &&
        planning
          .map((item) => {
            if (
              item.orderOfServiceId == orderOfServiceId &&
              item.activitiesId == activitiesId
            ) {
              return item;
            }
          })
          .filter((item) => item != undefined);
      if (result.length) {
        setIsUpdate(true);
        const personnels = await loadQuery(result[0].id);
        setData(personnels);
        setForm({
          ...form,
          id: result[0].id,
          date: result[0].date,
          description: result[0].description,
        });
      } else {
        setForm({ ...form, description: "", date: "" });
        setData([]);
        setIsUpdate(false);
      }
    } else {
      setData([]);
      setForm({ ...form, description: "", date: "" });
    }
  };

  const handleReset = () => {
    setValidated(false);
    setValidatedItems();
    setForm({ ...initialState });
    setFormItems({ ...initialFormItems });
    setData([]);
    loadPlannings();
    setIsUpdate(false);
    setDataItem([]);
  };

  const resetByOs = () => {
    setValidated(false);
    setValidatedItems();
    setForm({ ...form, activitiesId: "", description: "", date: "" });
    setFormItems({ ...initialFormItems });
    setData([]);
    loadPlannings();
    setIsUpdate(false);
  };

  const noPuntoComa = (event) => {
    var e = event || window.event;
    var key = e.keyCode || e.which;

    if (key === 110 || key === 190 || key === 188) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    loadOrderOfServices();
    loadPlannings();
    loadEmployees();
  }, []);

  useEffect(() => {
    loadActivities(form.orderOfServiceId);
  }, [form.orderOfServiceId]);

  useEffect(() => {
    loadRegister(form.orderOfServiceId, form.activitiesId);
  }, [form.orderOfServiceId, form.activitiesId]);

  useEffect(() => {
    resetByOs(form.orderOfServiceId);
  }, [form.orderOfServiceId]);

  return (
    <>
      <Card.Body>
        <Form noValidate={true} className={validated ? "was-validated" : ""}>
          <Row>
            <Col lg="3">
              <Form.Group>
                <p className="labelStyle">Orden de Trabajo *</p>
                <Form.Control
                  as="select"
                  name="orderOfServiceId"
                  value={form.orderOfServiceId}
                  onChange={handleChange}
                  placeholder="services"
                  required
                >
                  <option value="">Seleccione</option>
                  {orderOfServices &&
                    orderOfServices.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.numberOs} - {item.nameOs}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col lg="6">
              <Form.Group>
                <p className="labelStyle">Actividad *</p>
                <Form.Control
                  as="select"
                  name="activitiesId"
                  value={form.activitiesId}
                  onChange={handleChange}
                  placeholder=""
                  required
                >
                  <option value="">
                    {activities.length ? "Seleccione" : "Os sin actividades"}
                  </option>
                  {activities &&
                    activities.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.item} - {item.description}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Fecha *</p>
                <Form.Control
                  type="date"
                  name="date"
                  value={form.date}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm="12">
              <Form.Group>
                <p className="labelStyle">Descripción</p>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={form.description}
                  onChange={handleChange}
                  placeholder="Descripción"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <hr />
        <Form
          noValidate={true}
          className={validatedItems ? "was-validated" : ""}
        >
          <Row>
            <Col sm="6">
              <Form.Group>
                <p className="labelStyle">Mano de obra *</p>
                <Form.Control
                  as="select"
                  name="employeesId"
                  value={formItems.employeesId}
                  onChange={handleChangeItems}
                  required
                >
                  <option value="">Seleccione</option>
                  {employees &&
                    employees.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.fullName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm="6">
              <Form.Group>
                <p className="labelStyle">Duración [d]*</p>
                <Form.Control
                  type="number"
                  name="timers"
                  value={formItems.timers}
                  onChange={handleChangeItems}
                  placeholder="Tiempo"
                  onKeyDown={noPuntoComa}
                  min="1"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Button size="sm" variant="outline-success" onClick={addItems}>
            {isUpdateItem ? "Actualizar empleado" : "Agregar empleado"}
          </Button>
          <Row>
            <Col sm="12">
              <MaterialTable
                columns={[
                  {
                    title: "Mano de obra",
                    field: "employeeName",
                    headerStyle: { fontWeight: "bold" },
                  },
                  {
                    title: "Tiempo",
                    field: "timers",
                    headerStyle: { fontWeight: "bold" },
                  },
                ]}
                title=" "
                data={data}
                options={materialTable.options}
                localization={materialTable.localization}
                editable={{
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataDelete = [...data];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        setData([...dataDelete]);

                        resolve();
                      }, 1000);
                    }),
                }}
                actions={[
                  {
                    icon: () => <FontAwesomeIcon icon={faPen} />,
                    tooltip: "Editar",
                    onClick: (event, rowData) => {
                      handleUpdate(rowData);
                    },
                  },
                ]}
              />
            </Col>
          </Row>
        </Form>
        {data.length > 0 && (
          <div>
            <Button variant="primary" onClick={handleSubmit}>
              {isUpdate ? "Actualizar" : "Guardar"}
            </Button>
            {isUpdate && (
              <Button
                variant="danger"
                onClick={() => {
                  deleteRow(form.id);
                }}
              >
                Eliminar
              </Button>
            )}
          </div>
        )}
        <MaterialTable
          columns={[
            {
              title: "Item",
              field: "item",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Descripción",
              field: "description",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Orden de Trabajo",
              field: "orderOfServiceName",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Unidad",
              field: "unitName",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Cantidad",
              field: "amount",
              headerStyle: { fontWeight: "bold" },
            },
          ]}
          data={dataItem}
          title={`Actividades`}
          options={materialTable.options}
          localization={materialTable.localization}
          actions={[
            {
              icon: () => <FontAwesomeIcon icon={faPen} />,
              tooltip: "Editar",
              onClick: (event, rowData) => {
                setForm({ ...form, activitiesId: rowData.id });
              },
            },
          ]}
        />
      </Card.Body>
    </>
  );
}
