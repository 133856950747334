import React from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo_oscuro.png";
import { useSelector } from "react-redux";

const Wellcome = () => {
  const user = useSelector(({ user }) => user.value);
  console.log(`user`, user)
  return (
    <Container className="login-container">
      <Form className="container text-center">
          <Row className="mb-2">
            <Col sm="12">
              <a href="/login" className="mb-3">
                <img src={logo} alt="Logo" width="35%" />
              </a>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <h3>{`Bienvenid@. ${user?.firtsName} ${user?.lastName}`}</h3>
            </Col>
          </Row>
      </Form>
    </Container>
  );
};

export default Wellcome;
