import React, { useState, useEffect } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import MaterialTable from "material-table";
import materialTable from "../../utils/materialTableUtils";
import "../../css/global.css";
import apiStrapi from "../../utils/api/apiStrapi";

const initialState = {
  orderOfServiceId: "",
};

export default function WorkProgress() {
  const [form, setForm] = useState({ ...initialState });
  const [data, setData] = useState([]);
  const [orderOfServices, setOrderOfServices] = useState([]);
  const [activities, setActivities] = useState([]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const loadOrderOfServices = async () => {
    try {
      const result = await apiStrapi.get("order-of-services?_limit=-1");
      if (result.data) setOrderOfServices(result.data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadActivities = async () => {
    try {
      const result = await apiStrapi.get("activities");
      if (result.data) setActivities(result.data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadWorkProgress = async (orderOfServiceId) => {
    try {
      if (orderOfServiceId != "") {
        const result = await apiStrapi.get(
          `work-progresses?_where[orderOfServiceId.id]=${orderOfServiceId}`
        );
        if (result.data) {
          let dataFilter = [];
          activities.length &&
            activities.map((element) => {
              let amount = 0;
              let items = [];
              result.data.forEach((item) => {
                if (element.id == item.activitiesId.id) {
                  amount = amount + parseInt(item.amount, 10);
                  item.amount = amount;
                  const model = {
                    orderOfServiceId: item.orderOfServiceId.id,
                    orderOfServiceName: item.orderOfServiceId.nameOs,
                    date1: item.date1,
                    date2: item.date2,
                    activitiesId: item.activitiesId.id,
                    activitiesName: item.activitiesId.description,
                    unitId: item.unitId.id,
                    unitName: item.unitId.name,
                    amount: item.amount,
                  };
                  items.push(model);
                }
              });
              const position = items.length - 1;
              dataFilter.push(items[position]);
            });
          const filter = dataFilter.filter((elem) => elem != undefined);
          setData(filter);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    loadWorkProgress(form.orderOfServiceId);
  }, [form.orderOfServiceId]);

  useEffect(() => {
    loadActivities();
    loadOrderOfServices();
  }, []);

  return (
    <>
      <Card.Body>
        <Form>
          <Row>
            <Col sm="4">
              <Form.Group>
                <p className="labelStyle">Orden de Trabajo</p>
                <Form.Control
                  as="select"
                  name="orderOfServiceId"
                  value={form.orderOfServiceId}
                  onChange={handleChange}
                  placeholder="services"
                  required
                >
                  <option value="">Seleccione</option>
                  {orderOfServices &&
                    orderOfServices.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.numberOs} - {item.nameOs}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <MaterialTable
          columns={[
            {
              title: "Orden de Trabajo",
              field: "orderOfServiceName",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Fecha inicial",
              field: "date1",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Fecha final",
              field: "date2",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Actividad",
              field: "activitiesName",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Unidad",
              field: "unitName",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Cantidad",
              field: "amount",
              headerStyle: { fontWeight: "bold" },
            },
          ]}
          data={data}
          title={`Avance de obra`}
          options={materialTable.options}
          localization={materialTable.localization}
        />
      </Card.Body>
    </>
  );
}
