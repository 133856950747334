import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import "../../css/global.css";
import MaterialTable from "material-table";
import materialTable from "../../utils/materialTableUtils";
import { message, customConfirm } from "../../utils/notification";
import apiStrapi from "../../utils/api/apiStrapi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

const initialState = {
  name: "",
  address: "",
  email: "",
  phone: "",
  nit: "",
  city: "",
  statusesId: 1,
};

export default function Clients() {
  const [form, setForm] = useState({ ...initialState });
  const [isUpdate, setIsUpdate] = useState(false);
  const [clients, setClients] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [validated, setValidated] = useState(false);

  const loadClients = async () => {
    try {
      const result = await apiStrapi.get("clients?_limit=-1");
      const data = result.data;
      data.map((item) => {
        const statusesName =
          item.statusesId != null ? item.statusesId.name : null;
        const statusesId = item.statusesId != null ? item.statusesId.id : null;
        item.statusesName = statusesName;
        item.statusesId = statusesId;
      });
      if (data) setClients(data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadStatuses = async () => {
    try {
      const result = await apiStrapi.get("statuses");
      const data = result.data;
      if (data) setStatuses(data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formControl = e.currentTarget;
    const isValid = formControl.checkValidity() !== false;
    setValidated(true);
    if (isValid) {
      try {
        isUpdate
          ? (await apiStrapi.put(`clients/${form.id}`, form)) &&
            message("Actualizado con exito", 1)
          : (await apiStrapi.post("clients", form)) &&
            message("Guardado con exito", 1);
        handleReset();
      } catch (error) {
        console.log("Error: ", error.message);
      }
    }
  };

  const deleteRow = async (rowData) => {
    try {
      customConfirm(`Esta seguro que sea eliminar ${rowData.name}`).then(
        async (confirm) => {
          if (confirm) {
            const result = await apiStrapi.delete(`clients/${rowData.id}`);
            if (result.error) {
              message(result.message, 2);
            } else {
              loadClients();
              message(`${rowData.name} Eliminado con exito`, 1);
            }
          }
        }
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleReset = () => {
    loadClients();
    setIsUpdate(false);
    setForm({ ...initialState });
    setValidated(false);
  };

  useEffect(() => {
    loadClients();
    loadStatuses();
  }, []);

  return (
    <>
      <Card.Body>
        <Form
          noValidate={true}
          className={validated ? "was-validated" : ""}
          onSubmit={handleSubmit}
        >
          <Row>
            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Nit *</p>
                <Form.Control
                  type="number"
                  name="nit"
                  value={form.nit}
                  onChange={handleChange}
                  placeholder="Nit"
                  min="1"
                  required
                />
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Nombre completo *</p>
                <Form.Control
                  type="text"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                  placeholder="Numero de orden"
                  required
                />
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Ciudad *</p>
                <Form.Control
                  type="text"
                  name="city"
                  value={form.city}
                  onChange={handleChange}
                  placeholder="Ciudad"
                />
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Dirección *</p>
                <Form.Control
                  type="text"
                  name="address"
                  value={form.address}
                  onChange={handleChange}
                  placeholder="Dirección"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Correo *</p>
                <Form.Control
                  type="text"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  placeholder="Correo"
                />
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Telefono *</p>
                <Form.Control
                  type="number"
                  name="phone"
                  value={form.phone}
                  onChange={handleChange}
                  placeholder="Telefono"
                  min="0"
                />
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Estado *</p>
                <Form.Control
                  as="select"
                  name="statusesId"
                  value={form.statusesId}
                  onChange={handleChange}
                  required
                >
                  <option value="">Seleccione</option>
                  {statuses &&
                    statuses.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Button variant="primary" type="submit">
            {isUpdate ? "Actualizar" : "Guardar"}
          </Button>
          {isUpdate && (
            <Button variant="danger" onClick={handleReset}>
              Cancelar
            </Button>
          )}
        </Form>
        <MaterialTable
          columns={[
            {
              title: "Id",
              field: "id",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Nit",
              field: "nit",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Nombre",
              field: "name",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Direcciòn",
              field: "address",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Ciudad",
              field: "city",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Correo",
              field: "email",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Telefono",
              field: "phone",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Estado",
              field: "statusesName",
              headerStyle: { fontWeight: "bold" },
            },
          ]}
          // isLoading={data.loading}
          options={materialTable.options}
          localization={materialTable.localization}
          data={clients}
          title="Registo de clientes"
          actions={[
            {
              icon: () => <FontAwesomeIcon icon={faPen} />,
              tooltip: "Editar",
              onClick: (event, rowData) => {
                setIsUpdate(true);
                setForm(rowData);
              },
            },
            {
              icon: () => <FontAwesomeIcon icon={faTrash} />,
              tooltip: "Eliminar",
              onClick: (event, rowData) => deleteRow(rowData),
            },
          ]}
        />
      </Card.Body>
    </>
  );
}
