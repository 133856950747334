import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import MaterialTable from "material-table";
import materialTable from "../../../utils/materialTableUtils";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import apiStrapi from "../../../utils/api/apiStrapi";
import { message, customConfirm } from "../../../utils/notification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

export default function UpdateReturn(props) {
  const [show, setShow] = useState(false);
  const [operationType, setOperationType] = useState({ id: 2 });
  const [isReturn, setIsReturn] = useState(true);
  const [inputValue, setInputValue] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    setOperationType({ ...operationType, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //Devolucion
    if (operationType.id == 2) {
      try {
        const data = new FormData();
        data.append("files.image", props.file.image);
        data.append("data", JSON.stringify(props.formUpdate));
        if (props.form.image != null) {
          await apiStrapi.delete(`upload/files/${props.form.image.id}`);
        }
        const result = await apiStrapi.put(
          `returns/${props.formUpdate.id}`,
          data
        );
        if (result) {
          message("Actualizado con exito", 1);
          props.loadReturns();
          handleReset();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
    //Remanente
    if (operationType.id == 3) {
      try {
        const data = new FormData();
        data.append("files.image", props.file.image);
        data.append("data", JSON.stringify(props.formUpdate));
        if (props.form.image != null) {
          await apiStrapi.delete(`upload/files/${props.form.image.id}`);
        }
        const result = await apiStrapi.put(
          `remainders/${props.formUpdate.id}`,
          data
        );
        if (result) {
          message("Actualizado con exito", 1);
          props.loadRemainders();
          handleReset();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const loadAutocomplete = async (materialToolsId) => {
    if (materialToolsId) {
      try {
        const result = await apiStrapi.get(
          `material-tools?_where[id]=${materialToolsId}`
        );
        const data = result.data[0];
        setInputValue(data);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const deleteRow = async (rowData) => {
    try {
      customConfirm(`Esta seguro que sea eliminarlo`).then(async (confirm) => {
        if (confirm) {
          const result = await apiStrapi.delete(`returns/${rowData.id}`);
          if (result.error) {
            message(result.message, 2);
          } else {
            props.loadReturns();
            message(`Eliminado con exito`, 1);
          }
        }
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleReset = () => {
    handleClose();
    props.setFormUpdate({ ...props.initialStateUpdate });
  };

  const handleOperationType = (id) => {
    if (id == 2) {
      setIsReturn(true);
    }
    if (id == 3) {
      setIsReturn(false);
    }
  };

  useEffect(() => {
    loadAutocomplete(props.formUpdate.materialToolsId);
  }, [props.formUpdate.materialToolsId]);

  useEffect(() => {
    handleOperationType(operationType.id);
  }, [operationType.id]);

  return (
    <Form>
      <Row>
        <Col sm="3">
          <Form.Group>
            <p className="labelStyle">Tipo de operación</p>
            <Form.Control
              as="select"
              name="id"
              value={operationType.id}
              onChange={handleChange}
              required
            >
              {props.operation &&
                props.operation.map(
                  (item) =>
                    item.id != 1 && (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    )
                )}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <MaterialTable
        columns={[
          {
            title: "Id",
            field: "id",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Empleado",
            field: "employeeName",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Orden de Trabajo",
            field: "orderOfServiceName",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Fecha",
            field: "date",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Descripción",
            field: "description",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Producto",
            field: "materialToolsName",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Cantidad",
            field: "amount",
            headerStyle: { fontWeight: "bold" },
          },
        ]}
        title="Registros de Devolucion/Remanente"
        data={isReturn ? props.returns : props.remainders}
        options={materialTable.options}
        localization={materialTable.localization}
        actions={[
          {
            icon: () => <FontAwesomeIcon icon={faPen} />,
            tooltip: "Editar",
            onClick: (event, rowData) => {
              handleShow();
              props.setFormUpdate(rowData);
            },
          },
          {
            icon: () => <FontAwesomeIcon icon={faTrash} />,
            tooltip: "Eliminar",
            onClick: (event, rowData) => deleteRow(rowData),
          },
        ]}
      />
      <Modal show={show} size="xl" onHide={handleClose}>
        <Modal.Header
          style={{ backgroundColor: "#0F1D37", color: "white" }}
          closeButton
        >
          <p className="h4">Actualizar devolución/remanente</p>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Empleado que entrega *</p>
                <Form.Control
                  as="select"
                  name="employeeId"
                  value={props.formUpdate.employeeId}
                  onChange={props.handleChangeUpdate}
                  required
                >
                  <option value="">Seleccione</option>
                  {props.employees &&
                    props.employees.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.fullName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Orden de Trabajo *</p>
                <Form.Control
                  as="select"
                  name="orderOfServiceId"
                  value={props.formUpdate.orderOfServiceId}
                  onChange={props.handleChangeUpdate}
                  placeholder="Producto"
                  required
                >
                  <option value="">Seleccione</option>
                  {props.orderOfServices &&
                    props.orderOfServices.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.nameOs}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Fecha *</p>
                <Form.Control
                  type="date"
                  name="date"
                  value={props.formUpdate.date}
                  onChange={props.handleChangeUpdate}
                  placeholder="Fecha"
                  required
                />
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Codigo de Producto *</p>
                <Autocomplete
                  id="combo-box-demo"
                  options={props.list}
                  value={inputValue}
                  getOptionLabel={(option) => option.code + " - " + option.name}
                  onChange={(e, v) =>
                    props.setFormUpdate({
                      ...props.formUpdate,
                      materialToolsId: v.id != null ? v.id : "",
                    })
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group>
                <p className="labelStyle">Cantidad *</p>
                <Form.Control
                  type="number"
                  name="amount"
                  onChange={props.handleChangeUpdate}
                  value={props.formUpdate.amount}
                  min="1"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Form.Group controlId="formFile" className="mb-3">
                <p className="labelStyle">Imagen</p>
                <Form.Control
                  onChange={props.handleChangeFile}
                  name="image"
                  type="file"
                />
              </Form.Group>
              {props.formUpdate.image != null && (
                <div className="d-flex justify-content-around align-items-center">
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      props.viewImage(props.formUpdate.image.url);
                    }}
                    src={`${
                      props.baseURL
                    }${props.formUpdate.image.formats.thumbnail.url.slice(1)}`}
                    alt="Ver imagen"
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Form.Group>
                <p className="labelStyle">Descripción/Remisión/Factura</p>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={props.formUpdate.description}
                  onChange={props.handleChangeUpdate}
                  placeholder="Descripción/Remisión/Factura"
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleReset}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Actualizar
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}
