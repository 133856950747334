import React, { useState, useEffect } from "react";
import apiStrapi from "../../utils/api/apiStrapi";
// react plugin used to create charts
import MaterialTable from "material-table";
import materialTable from "../../utils/materialTableUtils";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components

const options = {
  // // headerStyle: { backgroundColor: '#253053', color: 'white' },

  filtering: false,
  grouping: false,
  exportButton: true,
  actionsColumnIndex: -1,
  pageSize: 1000,
  paging: true,
  pageSizeOptions: [5, 10, 20, 50, 100, 500, 1000],
  emptyRowsWhenPaging: false,
  search: true,
  sorting: true,
  toolbar: true,
  padding: "dense",
};

function ReportPandG() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [entries, setEntries] = useState([]);
  const [exits, setExits] = useState([]);
  const [returns, setReturns] = useState([]);
  const [dataInventary, setDataInventary] = useState([]);

  const loadEntries = async () => {
    try {
      const result = await apiStrapi.get("items-of-entries?_limit=-1");
      const data = result.data;
      const dataEmtry = await apiStrapi.get("entries?_limit=-1");
      const materialTools = await apiStrapi.get("material-tools?_limit=-1");
      const newData =
        data.length &&
        data.reduce((array, item) => {
          if (item.items.length) {
            item.items.forEach((i) => {
              const entry =
                dataEmtry.data.length &&
                dataEmtry.data.filter((e) => e.id == item.entrieId.id && e);
              const materiaT =
                materialTools.data.length &&
                materialTools.data.filter(
                  (o) => o.id == i.materialToolsId && o
                );
              if (entry.length > 0 && materiaT.length > 0) {
                const model = {
                  id: entry[0].id,
                  date: entry[0].date,
                  orderOfServiceId: {
                    id: entry[0].orderOfServiceId.id,
                    nameOs: entry[0].orderOfServiceId.nameOs,
                    numberOs: entry[0].orderOfServiceId.numberOs,
                    centerOfCost: entry[0].orderOfServiceId.centerOfCost,
                    durationOs:
                      entry[0].orderOfServiceId.durationOs != undefined
                        ? entry[0].orderOfServiceId.durationOs
                        : 0,
                    valueOs: parseInt(entry[0].orderOfServiceId.value, 10),
                  },
                  amount: i.amount,
                  description: entry[0].description,
                  materialToolsId: {
                    id: materiaT[0].id,
                    code: materiaT[0].code,
                    value: parseInt(materiaT[0].value, 10),
                  },
                  image: entry[0].image,
                  MTCode: i.code,
                  clientId: entry[0].orderOfServiceId.clientId,
                };
                array.push(model);
              }
            });
          }
          return array;
        }, []);
      if (newData.length) {
        setEntries(newData);
        return newData;
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadReturns = async () => {
    try {
      const result = await apiStrapi.get("returns?_limit=-1");
      const data = result.data;
      if (data) setReturns(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadExits = async () => {
    try {
      const result = await apiStrapi.get("items-of-exits?_limit=-1");
      const dataExits = await apiStrapi.get("exits?_limit=-1");
      const data = result.data;
      const materialTools = await apiStrapi.get("material-tools?_limit=-1");
      const newData =
        data.length &&
        data.reduce((array, item) => {
          if (item.items.length) {
            item.items.forEach((i) => {
              const exit =
                dataExits.data.length &&
                dataExits.data.filter((e) => e.id == item.exitId.id && e);
              const materiaT =
                materialTools.data.length &&
                materialTools.data.filter(
                  (o) => o.id == i.materialToolsId && o
                );
              if (exit.length > 0 && materiaT.length > 0) {
                const model = {
                  id: exit[0].id,
                  date: exit[0].date,
                  orderOfServiceId: {
                    id: exit[0].orderOfServiceId.id,
                    nameOs: exit[0].orderOfServiceId.nameOs,
                    numberOs: exit[0].orderOfServiceId.numberOs,
                    centerOfCost: exit[0].orderOfServiceId.centerOfCost,
                    durationOs:
                      exit[0].orderOfServiceId.durationOs != undefined
                        ? exit[0].orderOfServiceId.durationOs
                        : 0,
                    valueOs: parseInt(exit[0].orderOfServiceId.value, 10),
                  },
                  amount: i.amount,
                  description: exit[0].description,
                  materialToolsId: {
                    id: materiaT[0].id,
                    code: materiaT[0].code,
                    value: parseInt(materiaT[0].value, 10),
                  },
                  image: exit[0].image,
                  MTCode: i.code,
                  clientId: exit[0].orderOfServiceId.clientId,
                };
                array.push(model);
              }
            });
          }
          return array;
        }, []);
      if (newData.length) {
        setExits(newData);
        return newData;
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadDataInvenary = async () => {
    try {
      const result = await apiStrapi.get("material-tools?_limit=-1");
      const data = result.data;
      data.map((item) => {
        const categoryName = item.category != null ? item.category.name : null;
        const category = item.category != null ? item.category.id : null;
        item.categoryName = categoryName;
        item.category = category;
        const UnitName = item.unitId != null ? item.unitId.name : null;
        const unitId = item.unitId != null ? item.unitId.id : null;
        item.UnitName = UnitName;
        item.unitId = unitId;
      });
      if (data.length) {
        setDataInventary(data);
      }
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };
  let amount = 0;
  const newData =
    dataInventary.length &&
    dataInventary.forEach((element) => {
      let amountEntry = 0;
      let amountReturn = 0;
      let amountExit = 0;
      entries &&
        entries.forEach((item) => {
          if (element.code == item.materialToolsId.code)
            amountEntry = amountEntry + item.amount;
        });
      returns &&
        returns.forEach((item) => {
          if (element.code == item.materialToolsId.code)
            amountReturn = amountReturn + item.amount;
        });
      exits &&
        exits.forEach((item) => {
          if (element.code == item.materialToolsId.code)
            amountExit = amountExit + item.amount;
        });

      element.amount = amountEntry + amountReturn - amountExit;

      element.costInventary =
        parseInt(element.amount, 10) * parseInt(element.value, 10);
      amount = amount + element.costInventary;
      element.totalStock = amount;
    });

  const loadProduction = async () => {
    try {
      const result = await apiStrapi.get(
        "construction-per-work-progresses?_limit=-1"
      );
      const data = result.data;
      if (data.length) {
        const produption = data.map((item) => {
          const model = {
            employeeId: item.employeeId.id,
            employeeName: item.employeeId.fullName,
            workProgressId: item.workProgressId.id,
            orderOfServiceId: item.workProgressId.orderOfServiceId,
            dayValue: item.employeeId.dayValue,
            timers: item.timers,
            totalToPay: item.timers * parseInt(item.employeeId.dayValue, 10),
          };
          return model;
        });
        const result = await apiStrapi.get(
          "order-of-services?_where[statusesId]=1&_limit=-1"
        );
        const orderOfservice = result.data;
        if (orderOfservice.length) {
          const dataaa = [];
          orderOfservice.map((item) => {
            if (produption.length) {
              let count = 0;
              let data;
              produption.map((i) => {
                if (i.orderOfServiceId == item.id) {
                  count = count + i.totalToPay;
                  i.valueFull = count;
                  data = i;
                }
              });
              dataaa.push(data);
            }
          });
          const newData = dataaa.filter((item) => item != undefined);
          return newData;
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const formatterPeso = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });

  const loadDataMaterial = async () => {
    try {
      const result = await apiStrapi.get("material-tools?_limit=-1");
      const data = result.data;
      if (data) {
        const dataExits = await loadExits();
        const dataReturn = await apiStrapi.get("returns?_limit=-1");

        let exitsResult = [];
        data.map((item) => {
          let amount = 0;
          const d =
            dataExits &&
            dataExits
              .map((i) => {
                if (i.materialToolsId.id == item.id) {
                  amount = amount + i.amount;
                  i.amount = amount;
                  return i;
                }
              })
              .filter((item) => item != undefined);
          if (d != undefined) {
            const position = d.length - 1;
            exitsResult.push(d[position]);
          }
        });

        let returnResult = [];
        data.map((item) => {
          let amount = 0;
          const d =
            dataReturn.data &&
            dataReturn.data
              .map((i) => {
                if (i.materialToolsId.id == item.id) {
                  amount = amount + i.amount;
                  i.amount = amount;
                  return i;
                }
              })
              .filter((item) => item != undefined);
          if (d != undefined) {
            const position = d.length - 1;
            returnResult.push(d[position]);
          }
        });
        const resultExits =
          exitsResult.length > 0
            ? exitsResult.filter((item) => item != undefined)
            : [];
        const resultReturn =
          returnResult.length > 0
            ? returnResult.filter((item) => item != undefined)
            : [];

        console.log(`resultExits`, resultExits);
        console.log(`resultReturn`, resultReturn);

        if (resultExits.length) {
          resultExits.forEach((item) => {
            let amount = 0;
            if (resultReturn.length) {
              resultReturn.forEach((i) => {
                if (i.materialToolsId.id == item.materialToolsId.id) {
                  amount = item.amount - i.amount;
                  item.amount = amount;
                }
              });
            }
          });
        }

        const dataTable =
          resultExits.length &&
          resultExits.map((item) => {
            const model = {
              clientId: item.clientId,
              orderOfServiceId: item.orderOfServiceId.id,
              nameOs: item.orderOfServiceId.nameOs,
              numberOs: item.orderOfServiceId.numberOs,
              centerOfCostOs: item.orderOfServiceId.centerOfCost,
              valueOs: item.orderOfServiceId.valueOs,
              materialToolsId: item.materialToolsId.id,
              durationOs: item.orderOfServiceId.durationOs,
              costOfSale:
                item.amount * parseInt(item.materialToolsId.value, 10),
            };
            return model;
          });

        const result = await apiStrapi.get(
          "order-of-services?_where[statusesId]=1"
        );
        const orderOfservice = result.data;
        const materialToolsData = [];
        orderOfservice.length &&
          orderOfservice.map((item) => {
            if (dataTable.length) {
              let dat;
              let count = 0;
              dataTable.map((i) => {
                if (item.id == i.orderOfServiceId) {
                  count = count + i.costOfSale;
                  dat = i;
                }
              });
              materialToolsData.push(dat);
            }
          });
        const materialTools = materialToolsData.filter(
          (item) => item != undefined
        );

        const personnels =
          (await loadProduction()) != undefined ? await loadProduction() : [];
        const materialT = [];
        if (materialTools.length) {
          materialTools.forEach((item) => {
            if (personnels.length) {
              personnels.forEach((i) => {
                if (item.orderOfServiceId == i.orderOfServiceId) {
                  materialT.push({ ...item, production: i.valueFull });
                } else {
                  materialT.push({ ...item, production: 0 });
                }
              });
            }
          });
        }

        const clients = await apiStrapi.get("clients?_limit=-1");
        const clientdata = clients.data;

        if (clientdata.length) {
          clientdata.forEach((item) => {
            materialT.length &&
              materialT.forEach((i) => {
                if (item.id == i.clientId) {
                  i.clientName = item.name;
                }
              });
          });
        }

        if (materialT.length) {
          setLoading(false);
          materialT.forEach((item) => {
            item.balance =
              parseFloat(item.valueOs) -
              parseFloat(item.production) -
              parseFloat(item.costOfSale);
          });
        }

        if (orderOfservice.length) {
          const os = orderOfservice.map((item) => {
            const model = {
              balance: formatterPeso.format(0),
              centerOfCostOs: item.centerOfCost,
              clientId: item.clientId.id,
              clientName: item.clientId.name,
              costOfSale: formatterPeso.format(0),
              durationOs: item.duration,
              nameOs: item.nameOs,
              numberOs: item.numberOs,
              orderOfServiceId: item.id,
              production: formatterPeso.format(0),
              valueOs: item.value ? formatterPeso.format(item.value) : "",
            };
            return model;
          });

          if (os.length) {
            os.forEach((item) => {
              if (materialT.length) {
                materialT.map((i) => {
                  if (item.orderOfServiceId == i.orderOfServiceId) {
                    item.balance = i.balance
                      ? formatterPeso.format(i.balance)
                      : "";
                    item.costOfSale = i.costOfSale
                      ? formatterPeso.format(i.costOfSale)
                      : "";
                    item.materialToolsId = i.materialToolsId;
                    item.production = i.production
                      ? formatterPeso.format(i.production)
                      : "";
                  }
                });
              }
            });
            setData(os);
            setLoading(false);
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    loadDataMaterial();

    loadEntries();
    loadReturns();
    loadExits();
    loadDataInvenary();
  }, []);
  return (
    <>
      <Row>
        <Col md="12">
          <MaterialTable
            columns={[
              {
                title: "Cliente",
                field: "clientName",
                headerStyle: { fontWeight: "bold" },
              },
              {
                title: "Numero de la orden",
                field: "numberOs",
                headerStyle: { fontWeight: "bold" },
              },
              {
                title: "Nombre de la orden",
                field: "nameOs",
                headerStyle: { fontWeight: "bold" },
              },
              {
                title: "Centro de costo",
                field: "centerOfCostOs",
                headerStyle: { fontWeight: "bold" },
              },
              {
                title: "Valor de la OS",
                field: "valueOs",
                headerStyle: { fontWeight: "bold" },
              },
              {
                title: "Duración de la OS",
                field: "durationOs",
                headerStyle: { fontWeight: "bold" },
              },
              {
                title: "Producción",
                field: "production",
                headerStyle: { fontWeight: "bold" },
              },
              {
                title: "Costo de venta",
                field: "costOfSale",
                headerStyle: { fontWeight: "bold" },
              },
              {
                title: "Balance",
                field: "balance",
                headerStyle: { fontWeight: "bold" },
              },
              // {
              //   title: "Pendiente por facturar",
              //   field: "amount",
              //   headerStyle: { fontWeight: "bold" },
              // },
            ]}
            isLoading={loading}
            options={options}
            localization={materialTable.localization}
            data={data}
            title="RESUMEN O/S"
          />
        </Col>
      </Row>
    </>
  );
}

export default ReportPandG;
