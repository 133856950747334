import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";

//import logo from "logo.svg";
import logo from "../../assets/img/logowhite.png";
// import logo from "../../assets/img/assisprom1.png";
import { useSelector } from "react-redux";
import apiStrapi from "../../utils/api/apiStrapi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck } from "@fortawesome/free-solid-svg-icons";

var ps;

function Sidebar(props) {
  const user = useSelector(({ user }) => user.value);
  const [items, setItems] = useState([]);
  const sidebar = React.useRef();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  const menuData = async () => {
    const { data } = await apiStrapi.get("permissions?_limit=-1");
    if (props.routes.length) {
      const listMenu = props.routes.reduce((array, item) => {
        const valid = data?.some(
          (element) =>
            user.roleId.id == element.roleId.id &&
            item.permissionId == element.namePermissionId.id
        );
        if (valid) array.push(item);
        return array;
      }, []);
      setItems(listMenu);
    }
  };

  React.useEffect(() => {
    menuData();
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  }, []);

  console.log("user", user);

  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo">
        <a className="simple-text logo-normal m-0">
          <div className="simple-text logo-normal text-center">
            <img src={logo} alt="react-logo" />
          </div>
        </a>
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        <div className="text-white text-center mt-3">
          <FontAwesomeIcon className="mx-2" icon={faUserCheck} />
          {user?.firtsName} {user?.lastName}
        </div>
        <Nav>
          {items.length > 0 &&
            items.map((prop, key) => {
              return (
                <li
                  className={
                    activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                  }
                  key={key}
                >
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              );
            })}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
