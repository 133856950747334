import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Card } from "react-bootstrap";
import MaterialTable from "material-table";
import materialTable from "../../utils/materialTableUtils";
import { message, customConfirm } from "../../utils/notification";
import apiStrapi from "../../utils/api/apiStrapi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import "../../css/global.css";

const initialState = {
  nit: "",
  name: "",
  address: "",
  phone: "",
  statusesId: 1,
};
export default function Suplier() {
  const [form, setForm] = useState({ ...initialState });
  const [data, setData] = useState({ list: [], loading: true });
  const [isUpdate, setIsUpdate] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [validated, setValidated] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formControl = e.currentTarget;
    const isValid = formControl.checkValidity() !== false;
    setValidated(true);
    if (isValid) {
      try {
        isUpdate
          ? (await apiStrapi.put(`supliers/${form.id}`, form)) &&
            message("Actualizado con exito", 1)
          : (await apiStrapi.post("supliers", form)) &&
            message("Guardado con exito", 1);
        handleReset();
      } catch (error) {
        console.log("Error: ", error.message);
      }
    }
  };

  const loadData = async () => {
    try {
      const result = await apiStrapi.get("supliers?_limit=-1");
      result.data
        ? setData({ list: result.data, loading: false })
        : setData({ list: [], loading: false });
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadStatuses = async () => {
    try {
      const result = await apiStrapi.get("statuses");
      const data = result.data;
      if (data) setStatuses(data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const deleteRow = async (rowData) => {
    try {
      customConfirm(`Esta seguro que sea eliminar ${rowData.name}`).then(
        async (confirm) => {
          if (confirm) {
            const result = await apiStrapi.delete(`supliers/${rowData.id}`);
            if (result.error) {
              message(result.message, 2);
            } else {
              loadData();
              message(`${rowData.name} Eliminado con exito`, 1);
            }
          }
        }
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleReset = () => {
    loadData();
    setIsUpdate(false);
    setForm({ ...initialState });
    setValidated(false);
  };

  useEffect(() => {
    loadData();
    loadStatuses();
  }, []);


  return (
    <>
      <Card.Body>
        <Form
          noValidate={true}
          className={validated ? "was-validated" : ""}
          onSubmit={handleSubmit}
        >
          <Row>
            <Col sm="4">
              <Form.Group>
                <p className="labelStyle">Nit *</p>
                <Form.Control
                  type="number"
                  name="nit"
                  value={form.nit}
                  onChange={handleChange}
                  placeholder="Nit del proveedor"
                  min="1"
                  required
                />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group>
                <p className="labelStyle">Nombre *</p>
                <Form.Control
                  type="text"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                  placeholder="Nombre"
                  required
                />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group>
                <p className="labelStyle">Dirección *</p>
                <Form.Control
                  type="text"
                  name="address"
                  value={form.address}
                  onChange={handleChange}
                  placeholder="Dirección"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <Form.Group>
                <p className="labelStyle">Telefono *</p>
                <Form.Control
                  type="number"
                  name="phone"
                  value={form.phone}
                  onChange={handleChange}
                  placeholder="Telefono"
                  min="0"
                />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group>
                <p className="labelStyle">Estado *</p>
                <Form.Control
                  as="select"
                  name="statusesId"
                  value={form.statusesId}
                  onChange={handleChange}
                  required
                >
                  <option value="">Seleccione</option>
                  {statuses &&
                    statuses.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Button variant="primary" type="submit">
            {isUpdate ? "Actualizar" : "Guardar"}
          </Button>
          {isUpdate && (
            <Button variant="danger" onClick={handleReset}>
              Cancelar
            </Button>
          )}
        </Form>
        <MaterialTable
          columns={[
            {
              title: "Nit",
              field: "nit",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Nombre",
              field: "name",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Dirección",
              field: "address",
              headerStyle: { fontWeight: "bold" },
            },
            {
              title: "Telefono",
              field: "phone",
              headerStyle: { fontWeight: "bold" },
            },
          ]}
          //isLoading={data.loading}
          options={materialTable.options}
          localization={materialTable.localization}
          data={data.list}
          title="Registro de proveedores"
          actions={[
            {
              icon: () => <FontAwesomeIcon icon={faPen} />,
              tooltip: "Editar",
              onClick: (event, rowData) => {
                console.log("rowData :>> ", rowData);
                setIsUpdate(true);
                setForm(rowData);
              },
            },
            {
              icon: () => <FontAwesomeIcon icon={faTrash} />,
              tooltip: "Eliminar",
              onClick: (event, rowData) => deleteRow(rowData),
            },
          ]}
        />
      </Card.Body>
    </>
  );
}
