import React, { useState, useEffect } from "react";
import apiStrapi from "../../../utils/api/apiStrapi";
import { message } from "../../../utils/notification";
import { Card, Button } from "react-bootstrap";
import CreateEntry from "./createReturn";
import UpdateEntry from "./updateReturn";
import { baseURL } from "../../../utils/api/apiStrapi";

const initialState = {
  employeeId: "",
  orderOfServiceId: "",
  date: "",
  description: "",
  operationTypeId: "",
  image: null,
};

const initialStateUpdate = {
  employeeId: "",
  date: "",
  orderOfServiceId: "",
  amount: "",
  description: "",
  materialToolsId: "",
  image: null,
};

const initialItems = {
  materialToolsId: "",
  code: "",
  name: "",
  reference: "",
  numberOfReference: "",
  amount: "",
};

const columns = [
  {
    title: "Codigo",
    field: "code",
    headerStyle: { fontWeight: "bold" },
  },
  { title: "Nombre", field: "name", headerStyle: { fontWeight: "bold" } },
  {
    title: "Numero de parte",
    field: "reference",
    headerStyle: { fontWeight: "bold" },
  },
  {
    title: "Numero de serial",
    field: "numberOfReference",
    headerStyle: { fontWeight: "bold" },
  },
  {
    title: "Cantidad",
    field: "amount",
    type: "numeric",
    headerStyle: { fontWeight: "bold" },
  },
];

const initialInputValue = { id: 1, name: "Seleccione" };

export default function ReturnRemainderTools() {
  const [form, setForm] = useState({ ...initialState });
  const [formUpdate, setFormUpdate] = useState({ ...initialStateUpdate });
  const [remainders, setRemainders] = useState([]);
  const [returns, setReturns] = useState([]);
  const [orderOfServices, setOrderOfServices] = useState([]);
  const [list, setList] = useState([]);
  const [items, setItems] = useState({ ...initialItems });
  const [data, setData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [operation, setOperation] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [validated, setValidated] = useState(false);
  const [validatedItems, setValidatedItems] = useState(false);
  const [file, setFile] = useState({ image: "" });
  const [isUpdateItem, setIsUpdateItem] = useState(false);
  const [inputValue, setInputValue] = useState({ ...initialInputValue });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleChangeUpdate = (e) => {
    setFormUpdate({ ...formUpdate, [e.target.name]: e.target.value });
  };

  const handleChangeFile = (e) => {
    setFile({ image: e.target.files[0] });
  };

  const loadEmployees = async () => {
    try {
      const result = await apiStrapi.get("employees");
      result.data ? setEmployees(result.data) : setEmployees([]);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const handleSubmit = async (e) => {
    if (!form.operationTypeId) message("Seleccione el tipo de operación", 2);
    e.preventDefault();
    //Devolucion
    if (form.operationTypeId == 2) {
      console.log("Devolucion");
      try {
        data &&
          data.map(async (item) => {
            const model = {
              employeeId: form.employeeId,
              date: form.date,
              orderOfServiceId: form.orderOfServiceId,
              description: form.description,
              materialToolsId: item.materialToolsId,
              amount: item.amount,
              operationTypeId: form.operationTypeId,
            };
            const formControl = e.currentTarget;
            const isValid = formControl.checkValidity() !== false;
            setValidated(true);
            if (isValid) {
              const dataNew = new FormData();
              dataNew.append("files.image", file.image);
              dataNew.append("data", JSON.stringify(model));
              const result = await apiStrapi.post("returns", dataNew);
              if (result) {
                message("Devolucion registrada con exito", 1);
                loadReturns();
                handleReset();
              }
            }
          });
      } catch (error) {
        console.log("Error: ", error.message);
      }
    }
    //Remanente
    if (form.operationTypeId == 3) {
      try {
        data &&
          data.map(async (item) => {
            const model = {
              employeeId: form.employeeId,
              date: form.date,
              orderOfServiceId: form.orderOfServiceId,
              description: form.description,
              materialToolsId: item.materialToolsId,
              amount: item.amount,
            };
            const formControl = e.currentTarget;
            const isValid = formControl.checkValidity() !== false;
            setValidated(true);
            if (isValid) {
              const dataNew = new FormData();
              dataNew.append("files.image", file.image);
              dataNew.append("data", JSON.stringify(model));
              const result = await apiStrapi.post("remainders", dataNew);
              if (result) {
                message("Remanente registrado con exito", 1);
                loadRemainders();
                handleReset();
              }
            }
          });
      } catch (error) {
        console.log("Error: ", error.message);
      }
    }
  };

  const viewRegister = () => setIsUpdate(!isUpdate);

  const loadOrderOfServices = async () => {
    try {
      const result = await apiStrapi.get("order-of-services?_limit=-1");
      result.data ? setOrderOfServices(result.data) : setOrderOfServices([]);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadMaterialTools = async () => {
    try {
      const result = await apiStrapi.get("material-tools?_limit=-1");
      const data = result.data;
      data.map((item) => {
        const categoryName = item.category != null ? item.category.name : null;
        const category = item.category != null ? item.category.id : null;
        item.categoryName = categoryName;
        item.category = category;
      });
      if (data) setList(data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadReturns = async () => {
    try {
      const result = await apiStrapi.get("returns?_limit=-1");
      const data = result.data;
      data.map((item) => {
        const employeeName =
          item.employeeId != null ? item.employeeId.fullName : null;
        const employeeId = item.employeeId != null ? item.employeeId.id : null;
        item.employeeName = employeeName;
        item.employeeId = employeeId;

        const orderOfServiceName =
          item.orderOfServiceId != null ? item.orderOfServiceId.nameOs : null;
        const orderOfServiceId =
          item.orderOfServiceId != null ? item.orderOfServiceId.id : null;
        item.orderOfServiceName = orderOfServiceName;
        item.orderOfServiceId = orderOfServiceId;

        const operationTypeName =
          item.operationTypeId != null ? item.operationTypeId.name : null;
        const operationTypeId =
          item.operationTypeId != null ? item.operationTypeId.id : null;
        item.operationTypeName = operationTypeName;
        item.operationTypeId = operationTypeId;

        const materialToolsName =
          item.materialToolsId != null ? item.materialToolsId.name : null;
        const materialToolsId =
          item.materialToolsId != null ? item.materialToolsId.id : null;
        item.materialToolsName = materialToolsName;
        item.materialToolsId = materialToolsId;
        const position = item.image.length - 1;
        item.image = item.image.length ? item.image[position] : null;
      });
      if (data) setReturns(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadRemainders = async () => {
    try {
      const result = await apiStrapi.get("remainders?_limit=-1");
      const data = result.data;
      data.map((item) => {
        const employeeName =
          item.employeeId != null ? item.employeeId.fullName : null;
        const employeeId = item.employeeId != null ? item.employeeId.id : null;
        item.employeeName = employeeName;
        item.employeeId = employeeId;

        const orderOfServiceName =
          item.orderOfServiceId != null ? item.orderOfServiceId.nameOs : null;
        const orderOfServiceId =
          item.orderOfServiceId != null ? item.orderOfServiceId.id : null;
        item.orderOfServiceName = orderOfServiceName;
        item.orderOfServiceId = orderOfServiceId;

        const operationTypeName =
          item.operationTypeId != null ? item.operationTypeId.name : null;
        const operationTypeId =
          item.operationTypeId != null ? item.operationTypeId.id : null;
        item.operationTypeName = operationTypeName;
        item.operationTypeId = operationTypeId;

        const materialToolsName =
          item.materialToolsId != null ? item.materialToolsId.name : null;
        const materialToolsId =
          item.materialToolsId != null ? item.materialToolsId.id : null;
        item.materialToolsName = materialToolsName;
        item.materialToolsId = materialToolsId;
        const position = item.image.length - 1;
        item.image = item.image.length ? item.image[position] : null;
      });
      if (data) setRemainders(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const addItems = (e) => {
    if (!isUpdateItem) {
      e.preventDefault();
      const formControl = e.currentTarget;
      const isValid = formControl.checkValidity() !== false;
      setValidatedItems(true);
      if (items.code == "") {
        message("Debe seleccionar un item", 2);
      } else if (items.amount == "") {
        message("Debe escribir una cantidad", 2);
      } else {
        const valit =
          data.length > 0 &&
          data.filter((item) => {
            if (item.materialToolsName == items.materialToolsName) return item;
          });
        if (!valit.length) {
          setData([...data, items]);
          setItems({ ...initialItems });
          setInputValue({ ...initialInputValue });
          setValidatedItems(false);
        } else {
          message("Este item ya esta agregado", 2);
        }
      }
    } else {
      const newData = data.filter((item) => item.code != items.code && item);
      const dataFinal = newData.concat(items);
      setData(dataFinal);
      setItems({ ...initialItems });
      setInputValue({ ...initialInputValue });
      setValidatedItems(false);
      setIsUpdateItem(false);
    }
  };

  const queryProdutByCode = (id) => {
    if (id) {
      list &&
        list.map(
          (item) =>
            item.id == id &&
            setItems({
              ...items,
              code: item.code,
              reference: item.reference,
              name: item.name,
            })
        );
    }
  };

  const viewImage = (image) => {
    window.open(`${baseURL}${image.slice(1)}`);
  };

  const handleReset = () => {
    setForm({ ...initialState });
    setFormUpdate({ ...initialStateUpdate });
    setItems({ ...items, amount: "" });
    setValidated(false);
    setValidatedItems(false);
    setIsUpdate(false);
    setData([]);
  };

  const loadOperationType = async () => {
    try {
      const result = await apiStrapi.get("operation-types");
      result.data ? setOperation(result.data) : setOperation([]);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  useEffect(() => {
    loadEmployees();
    loadOrderOfServices();
    loadMaterialTools();
    loadReturns();
    loadRemainders();
    loadOperationType();
  }, []);

  useEffect(() => {
    queryProdutByCode(items.materialToolsId);
  }, [items.materialToolsId]);

  return (
    <>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h2 className="titleHeader">
          {isUpdate
            ? "Actualización de Devolucion/Remanente"
            : "Devolucion/Remanente"}
        </h2>
        {/* <Button variant="primary" size="sm" onClick={viewRegister}>
          {isUpdate ? "Atras" : "Ver registros"}
        </Button> */}
      </Card.Header>
      <Card.Body>
        <CreateEntry
          handleSubmit={handleSubmit}
          form={form}
          handleChange={handleChange}
          employees={employees}
          items={items}
          data={data}
          setData={setData}
          setItems={setItems}
          orderOfServices={orderOfServices}
          list={list}
          addItems={addItems}
          columns={columns}
          operation={operation}
          validatedItems={validatedItems}
          validated={validated}
          handleChangeFile={handleChangeFile}
          setIsUpdateItem={setIsUpdateItem}
          inputValue={inputValue}
        />
        <UpdateEntry
          formUpdate={formUpdate}
          setFormUpdate={setFormUpdate}
          handleChangeUpdate={handleChangeUpdate}
          returns={returns}
          loadReturns={loadReturns}
          loadRemainders={loadRemainders}
          remainders={remainders}
          employees={employees}
          orderOfServices={orderOfServices}
          list={list}
          initialStateUpdate={initialStateUpdate}
          operation={operation}
          handleChangeFile={handleChangeFile}
          baseURL={baseURL}
          viewImage={viewImage}
          file={file}
          form={form}
          setItems={setItems}
        />
      </Card.Body>
    </>
  );
}
