import Dashboard from "views/Dashboard.jsx";
import Membership from "views/membership/home";
import CentersOfCosts from "views/centersOfCosts/home";
import Configuration from "views/configuration/home";
import MonitoringControl from "views/monitoringControl/home";
import WareHouse from "views/wareHouse/home";
import Reports from "views/reports/home";
import Wellcome from "views/wellcome";
import UpdateOs from "views/updateos";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-tv-2",
    component: Dashboard,
    layout: "/admin",
    permissionId: 27,
  },
  {
    path: "/wareHouse",
    name: "Almacén",
    icon: "nc-icon nc-box",
    component: WareHouse,
    layout: "/admin",
    permissionId: 1,
  },
  {
    path: "/centersOfCosts",
    name: "Centro de costo",
    icon: "nc-icon nc-money-coins",
    component: CentersOfCosts,
    layout: "/admin",
    permissionId: 15,
  },
  {
    path: "/monitoringControl",
    name: "Seguimiento y control",
    icon: "nc-icon nc-bell-55",
    component: MonitoringControl,
    layout: "/admin",
    permissionId: 18,
  },
  {
    path: "/reports",
    name: "Reportes",
    icon: "nc-icon nc-book-bookmark",
    component: Reports,
    layout: "/admin",
    permissionId: 22,
  },
  {
    path: "/configuration",
    name: "Configuración",
    icon: "nc-icon nc-settings-gear-65",
    component: Configuration,
    layout: "/admin",
    permissionId: 10,
  },

  {
    path: "/membership",
    name: "Membresía",
    icon: "nc-icon nc-single-02",
    component: Membership,
    layout: "/admin",
    permissionId: 6,
  },
  {
    path: "/wellcome",
    component: Wellcome,
    layout: "/admin",
  },
  {
    path: "/updateOs",
    component: UpdateOs,
    layout: "/admin",
  },
];
export default routes;
