import React, { useState, useEffect } from "react";
import { Card, Tabs, Tab, Spinner } from "react-bootstrap";
import OrderOfServices from "./orderOfServices";
import Activities from "./actitities/activities";
import "../../css/global.css";
import { useSelector } from "react-redux";
import apiStrapi from "../../utils/api/apiStrapi";

export default function Home() {
  const [key, setKey] = useState("orderOfServices");
  const [loading, setLoading] = useState(true);
  const user = useSelector(({ user }) => user.value);

  /////////Permisos del usuario /////////////////////
  const [ordenOfService, setOrderOfService] = useState(false);
  const [activities, setActivities] = useState(false);
  const namePermissionOrderOfService = 16;
  const namePermissionActivities = 17;
  ////////////////////////////////////////////////////

  const validatedPermissions = async () => {
    try {
      const result = await apiStrapi.get("permissions?_limit=-1");
      if (result.data.length) {
        const result1 = result.data.map(
          (item) =>
            item.namePermissionId.id == namePermissionOrderOfService &&
            item.roleId.id == user.roleId.id
        );

        if (result1) setOrderOfService(true);

        const result2 = result.data.map(
          (item) =>
            item.namePermissionId.id == namePermissionActivities &&
            item.roleId.id == user.roleId.id
        );

        if (result2) setActivities(true);

        if (result1) setKey("orderOfServices");
        else if (result2) setKey("activities");
        setLoading(false);
      }
    } catch (error) {
      console.log(`error.message`, error.message);
    }
  };

  useEffect(() => {
    validatedPermissions();
  }, []);

  return (
    <>
      <div className="content fluid">
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="grow" role="status">
              <span className="visually-hidden"></span>
            </Spinner>
          </div>
        ) : (
          <Card>
            <Tabs
              style={{
                fontSize: 15,
                margin: 2,
                backgroundColor: "",
                color: "#252422",
                fontWeight: "bold",
              }}
              className="m-3"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              unmountOnExit={true}
              mountOnEnter={true}
            >
              {ordenOfService && (
                <Tab
                  eventKey="orderOfServices"
                  title="Ordenes de Trabajo"
                  tabClassName="colorTab"
                >
                  <OrderOfServices />
                </Tab>
              )}
              {activities && (
                <Tab
                  eventKey="activities"
                  title="Actividades"
                  tabClassName="colorTab"
                >
                  <Activities />
                </Tab>
              )}
            </Tabs>
          </Card>
        )}
      </div>
    </>
  );
}
