import React, { useState, useEffect } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import MaterialTable from "material-table";
import pool from "@ricokahler/pool";
import materialTable from "../../utils/materialTableUtils";
import { message } from "../../utils/notification";
import "../../css/global.css";
import apiStrapi from "../../utils/api/apiStrapi";

export default function Permissions() {
  const [form, setForm] = useState({ roleId: "" });
  const [namePermissionsId, setNamePermissionsId] = useState([]);
  const [roles, setRoles] = useState([]);
  const [namePermissions, setNamePermissions] = useState([]);
  const [permissionsbyId, setPermissionsById] = useState([]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (namePermissionsId.length > 0) {
        const deletePermissions = await pool({
          collection: permissionsbyId,
          maxConcurrency: 1,
          task: async (item) => {
            const result = await apiStrapi.delete(`permissions/${item.id}`);
            return result;
          },
        });
        console.log("deletePermissions", deletePermissions);
        const savePermissions = await pool({
          collection: namePermissionsId,
          maxConcurrency: 1,
          task: async (item) => {
            const model = {
              namePermissionId: item,
              roleId: form.roleId,
            };
            const result = await apiStrapi.post("permissions", model);
            return result;
          },
        });
        console.log("savePermissions", savePermissions);
        message("Permisos asignados con exito", 1);
      } else {
        const deletePermissions = await pool({
          collection: permissionsbyId,
          maxConcurrency: 1,
          task: async (item) => {
            const result = await apiStrapi.delete(`permissions/${item.id}`);
            return result;
          },
        });
        console.log("deletePermissions 'else'", deletePermissions);
        message("Permisos eliminados con exito", 1);
      }
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadRoles = async () => {
    try {
      const result = await apiStrapi.get("roles");
      result.data ? setRoles(result.data) : setRoles([]);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadNamePermissions = async () => {
    try {
      const result = await apiStrapi.get("name-permissions?_sort=name:ASC");
      result.data ? setNamePermissions(result.data) : setNamePermissions([]);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadListPermissions = async (id) => {
    try {
      const result = await apiStrapi.get("permissions?_limit=-1");
      const data = [];
      if (result.data) {
        const permissions = [];
        result.data.map((item) => {
          const namePermissionId =
            item.namePermissionId != null ? item.namePermissionId.id : null;
          const namePermissionName =
            item.namePermissionId != null ? item.namePermissionId.name : null;
          const roleId = item.roleId != null ? item.roleId.id : null;
          const roleName = item.roleId != null ? item.roleId.name : null;
          item.namePermissionId = namePermissionId;
          item.namePermissionName = namePermissionName;
          item.roleId = roleId;
          item.roleName = roleName;
          if (item.roleId == id) data.push(item);
        });

        const namesPermissionsId = data
          ? data.map((item) => {
              permissions.push(item);
              return item.namePermissionId;
            })
          : [];
        setNamePermissionsId(namesPermissionsId);
        setPermissionsById(permissions);
      }
    } catch (error) {
      console.log("error.message :>> ", error.message);
    }
  };

  const handleChangePermissions = async (permissionsIds, rowData) => {
    let ids = permissionsIds.map((item) => item.id);
    if (rowData) {
      const namesPermissionsId = namePermissionsId.find(
        (id) => id === rowData.id
      );
      //Si existe se elimina del listado
      if (namesPermissionsId) {
        ids = namePermissionsId.filter((id) => id !== namesPermissionsId);
      } else {
        ids = namePermissionsId.concat([rowData.id]);
      }
    }
    setNamePermissionsId(ids);
  };

  useEffect(() => {
    loadRoles();
    loadNamePermissions();
  }, []);

  useEffect(() => {
    loadListPermissions(form.roleId);
  }, [form.roleId]);
  return (
    <>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col sm="4">
              <Form.Group>
                <p className="labelStyle">ROL</p>
                <Form.Control
                  as="select"
                  name="roleId"
                  value={form.roleId}
                  onChange={handleChange}
                  required
                >
                  <option value="">Seleccione</option>
                  {roles &&
                    roles.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Button type="submit">Guardar</Button>
        </Form>
        <MaterialTable
          columns={[
            {
              title: "Permiso",
              field: "name",
              headerStyle: { fontWeight: "bold" },
            },
          ]}
          localization={materialTable.localization}
          data={namePermissions}
          title="Permisos"
          options={{
            filtering: false,
            grouping: false,
            exportButton: true,
            actionsColumnIndex: -1,
            pageSize: 50,
            paging: true,
            pageSizeOptions: [50, 70, 90],
            emptyRowsWhenPaging: false,
            search: true,
            sorting: true,
            toolbar: true,
            padding: "dense",
            selection: true,
            selectionProps: (row) => {
              return {
                checked: namePermissionsId
                  ? namePermissionsId.includes(row.id)
                  : false,
              };
            },
          }}
          onSelectionChange={(rows, rowData) =>
            handleChangePermissions(rows, rowData)
          }
        />
      </Card.Body>
    </>
  );
}
