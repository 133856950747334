import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import materialTable from "../../utils/materialTableUtils";
import apiStrapi from "../../utils/api/apiStrapi";

export default function ReportGeneral() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadEntries = async () => {
    try {
      const result = await apiStrapi.get("items-of-entries?_limit=-1");
      const data = result.data;
      const dataEmtry = await apiStrapi.get("entries?_limit=-1");
      const materialTools = await apiStrapi.get("material-tools?_limit=-1");
      const newData =
        data.length &&
        data.reduce((array, item) => {
          if (item.items.length) {
            item.items.forEach((i) => {
              const entry =
                dataEmtry.data.length &&
                dataEmtry.data.filter((e) => e.id == item.entrieId.id && e);
              const materiaT =
                materialTools.data.length &&
                materialTools.data.filter(
                  (o) => o.id == i.materialToolsId && o
                );
              if (entry.length > 0 && materiaT.length > 0) {
                const model = {
                  id: entry[0].id,
                  date: entry[0].date,
                  orderOfServiceId: {
                    id: entry[0].orderOfServiceId.id,
                    nameOs: entry[0].orderOfServiceId.nameOs,
                    numberOs: entry[0].orderOfServiceId.numberOs,
                    centerOfCost: entry[0].orderOfServiceId.centerOfCost,
                    durationOs:
                      entry[0].orderOfServiceId.durationOs != undefined
                        ? entry[0].orderOfServiceId.durationOs
                        : 0,
                    valueOs: parseInt(entry[0].orderOfServiceId.value, 10),
                  },
                  amount: i.amount,
                  description: entry[0].description,
                  materialToolsId: {
                    id: materiaT[0].id,
                    code: materiaT[0].code,
                    name: materiaT[0].name,
                    value: parseInt(materiaT[0].value, 10),
                  },
                  image: entry[0].image,
                  MTCode: i.code,
                  clientId: entry[0].orderOfServiceId.clientId,
                  indication: "Entrada",
                };
                array.push(model);
              }
            });
          }
          return array;
        }, []);
      if (newData.length) {
        return newData;
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadExits = async () => {
    try {
      const result = await apiStrapi.get("items-of-exits?_limit=-1");
      const dataExits = await apiStrapi.get("exits?_limit=-1");
      const data = result.data;
      const materialTools = await apiStrapi.get("material-tools?_limit=-1");
      const newData =
        data.length &&
        data.reduce((array, item) => {
          if (item.items.length) {
            item.items.forEach((i) => {
              const exit =
                dataExits.data.length &&
                dataExits.data.filter((e) => e.id == item.exitId.id && e);
              const materiaT =
                materialTools.data.length &&
                materialTools.data.filter(
                  (o) => o.id == i.materialToolsId && o
                );
              if (exit.length > 0 && materiaT.length > 0) {
                const model = {
                  id: exit[0].id,
                  date: exit[0].date,
                  orderOfServiceId: {
                    id: exit[0].orderOfServiceId.id,
                    nameOs: exit[0].orderOfServiceId.nameOs,
                    numberOs: exit[0].orderOfServiceId.numberOs,
                    centerOfCost: exit[0].orderOfServiceId.centerOfCost,
                    durationOs:
                      exit[0].orderOfServiceId.durationOs != undefined
                        ? exit[0].orderOfServiceId.durationOs
                        : 0,
                    valueOs: parseInt(exit[0].orderOfServiceId.value, 10),
                  },
                  amount: i.amount,
                  description: exit[0].description,
                  materialToolsId: {
                    id: materiaT[0].id,
                    code: materiaT[0].code,
                    name: materiaT[0].name,
                    value: parseInt(materiaT[0].value, 10),
                  },
                  image: exit[0].image,
                  MTCode: i.code,
                  clientId: exit[0].orderOfServiceId.clientId,
                  indication: "Salida",
                };
                array.push(model);
              }
            });
          }
          return array;
        }, []);
      if (newData.length) {
        return newData;
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadReturns = async () => {
    try {
      const result = await apiStrapi.get("returns?_limit=-1");
      const data = result.data;
      if (data.length) {
        const result = data.map((item) => {
          item.indication = "Devolución";
          return item;
        });
        return result;
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadRemainders = async () => {
    try {
      const result = await apiStrapi.get("remainders?_limit=-1");
      const data = result.data;
      if (data.length) {
        const result = data.map((item) => {
          item.indication = "Remanente";
          return item;
        });
        return result;
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadData = async () => {
    try {
      const entries = await loadEntries();
      const exits = await loadExits();
      const returns = await loadReturns();
      const remainder = await loadRemainders();

      const data1 = entries.concat(exits);
      const data2 = data1.concat(returns);
      const data3 = data2.concat(remainder);
      const dataFinal = data3.filter((item) => {
        if (item != undefined) {
          const codeMt =
            item.materialToolsId != null ? item.materialToolsId.code : null;
          const nameMt =
            item.materialToolsId != null ? item.materialToolsId.name : null;
          const amountMt = item.amount;

          item.codeMt = codeMt;
          item.nameMt = nameMt;
          item.amountMt = amountMt;
          return item;
        }
      });
      setData(dataFinal);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="content">
      <MaterialTable
        columns={[
          {
            title: "Id",
            field: "id",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Operación",
            field: "indication",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Fecha",
            field: "date",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Codigo",
            field: "codeMt",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Nombre",
            field: "nameMt",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Cantidad",
            field: "amountMt",
            headerStyle: { fontWeight: "bold" },
          },
        ]}
        options={materialTable.options}
        localization={materialTable.localization}
        data={data}
        isLoading={loading}
        title=" "
      />
    </div>
  );
}
