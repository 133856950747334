import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import "../../css/global.css";
import MaterialTable from "material-table";
import materialTable from "../../utils/materialTableUtils";
import { message, customConfirm } from "../../utils/notification";
import apiStrapi from "../../utils/api/apiStrapi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

const initialState = {
  fullName: "",
  identification: "",
  address: "",
  phone: "",
  smartPhone: "",
  dayValue: "",
  statusesId: 1,
  chargesId: "",
};

export default function EmployeeInactive() {
  const [form, setForm] = useState({ ...initialState });
  const [isUpdate, setIsUpdate] = useState(false);
  const [list, setList] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [validated, setValidated] = useState(false);
  const [listcharges, setListCharges] = useState([]);

  const formatterPeso = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });

  const loadData = async () => {
    try {
      const result = await apiStrapi.get("employees?_where[statusesId.id]=4&_limit=-1");
      const data = result.data;
      data.map((item) => {
        const statusesName =
          item.statusesId != null ? item.statusesId.name : null;
        const statusesId = item.statusesId != null ? item.statusesId.id : null;
        const chargesName = item.chargesId != null ? item.chargesId.name : null;
        const chargesId = item.chargesId != null ? item.chargesId.id : null;
        item.statusesName = statusesName;
        item.statusesId = statusesId;
        item.chargesName = chargesName;
        item.chargesId = chargesId;
        item.dayValue = item.dayValue;
        item.dayValueFront = item.dayValue ? formatterPeso.format(item.dayValue) : "";
      });
      if (data) setList(data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadStatuses = async () => {
    try {
      const result = await apiStrapi.get("statuses");
      const data = result.data;
      if (data) setStatuses(data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const loadCharges = async () => {
    try {
      const result = await apiStrapi.get("charges");
      const data = result.data;
      if (data) setListCharges(data);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formControl = e.currentTarget;
    const isValid = formControl.checkValidity() !== false;
    setValidated(true);
    if (isValid) {
      try {
        isUpdate
          ? (await apiStrapi.put(`employees/${form.id}`, form)) &&
            message("Actualizado con exito", 1)
          : (await apiStrapi.post("employees", form)) &&
            message("Guardado con exito", 1);
        handleReset();
      } catch (error) {
        console.log("Error: ", error.message);
      }
    }
  };

  const deleteRow = async (rowData) => {
    try {
      customConfirm(`Esta seguro que sea eliminar ${rowData.name}`).then(
        async (confirm) => {
          if (confirm) {
            const result = await apiStrapi.delete(`employees/${rowData.id}`);
            if (result.error) {
              message(result.message, 2);
            } else {
              loadData();
              message(`${rowData.name} Eliminado con exito`, 1);
            }
          }
        }
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  const noPuntoComa = (event) => {
    var e = event || window.event;
    var key = e.keyCode || e.which;

    if (key === 110 || key === 190 || key === 188) {
      e.preventDefault();
    }
  };

  const handleReset = () => {
    loadData();
    setIsUpdate(false);
    setForm({ ...initialState });
    setValidated(false);
  };

  useEffect(() => {
    loadCharges();
    loadData();
    loadStatuses();
  }, []);

  return (
    <Card.Body>
      <Form
        noValidate={true}
        className={validated ? "was-validated" : ""}
        onSubmit={handleSubmit}
      >
        <Row>
          <Col sm="3">
            <Form.Group>
              <p className="labelStyle">Nombre completo *</p>
              <Form.Control
                type="text"
                name="fullName"
                value={form.fullName}
                onChange={handleChange}
                placeholder="Nombre completo"
                required
              />
            </Form.Group>
          </Col>
          <Col sm="3">
            <Form.Group>
              <p className="labelStyle">Identificación *</p>
              <Form.Control
                type="number"
                name="identification"
                value={form.identification}
                onChange={handleChange}
                placeholder="Identificación"
                min="1"
                required
              />
            </Form.Group>
          </Col>
          <Col sm="3">
            <Form.Group>
              <p className="labelStyle">Dirección *</p>
              <Form.Control
                type="text"
                name="address"
                value={form.address}
                onChange={handleChange}
                placeholder="Dirección"
              />
            </Form.Group>
          </Col>
          <Col sm="3">
            <Form.Group>
              <p className="labelStyle">Telefono fijo *</p>
              <Form.Control
                type="number"
                name="phone"
                value={form.phone}
                onChange={handleChange}
                placeholder="Telefono fijo"
                min="0"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <Form.Group>
              <p className="labelStyle">Celular *</p>
              <Form.Control
                type="number"
                name="smartPhone"
                value={form.smartPhone}
                onChange={handleChange}
                placeholder="Celular"
                min="0"
              />
            </Form.Group>
          </Col>
          <Col sm="3">
            <Form.Group>
              <p className="labelStyle">Cargo *</p>
              <Form.Control
                as="select"
                name="chargesId"
                value={form.chargesId}
                onChange={handleChange}
                required
              >
                <option value="">Seleccione</option>
                {listcharges &&
                  listcharges.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm="3">
            <Form.Group>
              <p className="labelStyle">Valor dia</p>
              <Form.Control
                type="number"
                name="dayValue"
                value={form.dayValue}
                onChange={handleChange}
                min={1}
                onKeyDown={noPuntoComa}
                placeholder="Valor del dia"
                required
              />
            </Form.Group>
          </Col>
          <Col sm="3">
            <Form.Group>
              <p className="labelStyle">Estado *</p>
              <Form.Control
                as="select"
                name="statusesId"
                value={form.statusesId}
                onChange={handleChange}
                required
              >
                <option value="">Seleccione</option>
                {statuses &&
                  statuses.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" type="submit">
          {isUpdate ? "Actualizar" : "Guardar"}
        </Button>
        {isUpdate && (
          <Button variant="danger" onClick={handleReset}>
            Cancelar
          </Button>
        )}
      </Form>
      <MaterialTable
        columns={[
          {
            title: "Id",
            field: "id",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Nombre",
            field: "fullName",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Identificación",
            field: "identification",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Dirección",
            field: "address",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Telefono fijo",
            field: "phone",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Celular",
            field: "smartPhone",
            headerStyle: { fontWeight: "bold" },
          },

          {
            title: "Cargo",
            field: "chargesName",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Valor del dia",
            field: "dayValueFront",
            headerStyle: { fontWeight: "bold" },
          },
          {
            title: "Estado",
            field: "statusesName",
            headerStyle: { fontWeight: "bold" },
          },
        ]}
        // isLoading={data.loading}
        options={materialTable.options}
        localization={materialTable.localization}
        data={list}
        title="Registro de empleados"
        actions={[
          {
            icon: () => <FontAwesomeIcon icon={faPen} />,
            tooltip: "Editar",
            onClick: (event, rowData) => {
              console.log("rowData :>> ", rowData);
              setIsUpdate(true);
              setForm(rowData);
            },
          },
          {
            icon: () => <FontAwesomeIcon icon={faTrash} />,
            tooltip: "Eliminar",
            onClick: (event, rowData) => deleteRow(rowData),
          },
        ]}
      />
    </Card.Body>
  );
}
